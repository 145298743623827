import React from 'react'
import classes from './Schedule.module.css'

const Schedule = ({children, maxWidth}) => {
  return (
    <div className={classes.schedule} style={{maxWidth: maxWidth}}>
      {children}
    </div>
  )
}

export default Schedule