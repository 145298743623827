import React, { memo, useEffect, useState } from 'react'
import classes from './Trip.module.css'
import moment from 'moment'
import { Button } from '../Button/Button'
import { TICKETS_ROUTE, windowWidthMobile, windowWidthTablet } from '../../utils/consts'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Trip = memo(({data, withButtonConfirm = true}) => {
  let yearDepartureAt = moment(data.route.fromRouteStop.departureAt).format('YYYY')
  let yearArrivalAt = moment(data.route.toRouteStop.arrivalAt).format('YYYY')
  let dayMonthDepartureAt = moment(data.route.fromRouteStop.departureAt).format('D MMMM')
  let dayMonthArrivalAt = moment(data.route.toRouteStop.arrivalAt).format('D MMMM')
  let timedepartureAt = moment(data.route.fromRouteStop.departureAt).format('HH:mm')
  let timearrivalAt = moment(data.route.toRouteStop.arrivalAt).format('HH:mm')
  const selectedDate = useSelector(state => state.trips._selectedDate)
  const selectedFromStop = useSelector(state => state.trips._selectedFromStop)
  const selectedToStop = useSelector(state => state.trips._selectedToStop)
  const XHRisRunning = useSelector(state => state.settings._XHRisRunning)
  const [isWindowWidthTablet, setIsWindowWidthTablet] = useState(window.innerWidth <= windowWidthTablet)
  const [isWindowWidthMobile, setIsWindowWidthMobile] = useState(window.innerWidth <= windowWidthMobile)

  const [params] = useSearchParams()
  let date = params.get('date')
  let fromStop = params.get('fromStop')
  let toStop = params.get('toStop')

  const navigate = useNavigate()

  console.log(data)

  const searchTicket = () => {
    if (XHRisRunning) {
      return false
    }
    else {
      if (data == null) {
        console.log(`Ошибка получения данных, data = ${data}`)
      }
      else {
        navigate({
          pathname: TICKETS_ROUTE,
          search: `?${createSearchParams({
            id: data.id,
            date: selectedDate.format() || date,
            fromStop: selectedFromStop || fromStop,
            toStop: selectedToStop || toStop,
          })}`
        })
      }
    }
  }


  const onWindowWidthChange = () => {
    setIsWindowWidthTablet(window.innerWidth <= windowWidthTablet)
    setIsWindowWidthMobile(window.innerWidth <= windowWidthMobile)
  }
  useEffect(() => {
    window.addEventListener('resize', onWindowWidthChange);
    return () => window.removeEventListener('resize', onWindowWidthChange);
  }, [])

  return (
    <div className={classes['trip-container']}>
      <div className={classes.trip} key={data.id} onClick={function(e) {
        searchTicket()
      }}>
        <div className={`${classes['trip-column']} ${classes['trip-route']}`}>
          <div className={`${classes['trip-column-line']} ${classes['trip-column-line__header']}`}>
            <div className={classes['trip-column-title-container']}>
              <div className={classes['trip-column-title']}>
                {data.route.fromRouteStop.stop.name}
              </div>
              <svg className={classes['trip-column-title-separator']} width="183" height="64" viewBox="0 0 183 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M84 34C72.726 34 63.8686 43.6601 56.6925 52.3553C50.8232 59.467 41.941 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C41.941 0 50.8232 4.53298 56.6925 11.6447C63.8686 20.3399 72.726 30 84 30C95.274 30 104.131 20.3399 111.308 11.6447C117.177 4.53298 126.059 0 136 0C150.485 0 162.723 9.62455 166.666 22.8276C167.84 26.7577 171.136 30 175.237 30H181C182.105 30 183 30.8954 183 32C183 33.1046 182.105 34 181 34H175.237C171.136 34 167.84 37.2423 166.666 41.1724C162.723 54.3755 150.485 64 136 64C126.059 64 117.177 59.467 111.308 52.3553C104.131 43.6601 95.274 34 84 34Z" fill="#FAF1E6"/>
              </svg>
            </div>
            <div className={classes['trip-column-title']}>
                {data.route.toRouteStop.stop.name}
            </div>
          </div>
          <div className={`${classes['trip-column-line']} ${classes['trip-departureAndArrival']}`}>
            <div className={classes['trip-column-card']}>
              <div className={classes['trip-column-card-title']}>Отправление</div>
              <div className={classes['trip-column-card-column']}>
                <div className={classes['trip-column-card-column-line']}>
                  {dayMonthDepartureAt}, {yearDepartureAt}
                </div>
                <div className={classes['trip-column-card-separator']}></div>
                <div className={classes['trip-column-card-column-line']}>
                  {timedepartureAt}
                </div>
              </div>
            </div>
            <div className={classes['trip-column-card']}>
              <div className={classes['trip-column-card-title']}>Прибытие</div>
              <div className={classes['trip-column-card-column']}>
                <div className={classes['trip-column-card-column-line']}>
                  {dayMonthArrivalAt}, {yearArrivalAt}
                </div>
                <div className={classes['trip-column-card-separator']}></div>
                <div className={classes['trip-column-card-column-line']}>
                  {timearrivalAt}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${classes['trip-column']} ${classes['trip-price']}`}>
          <div className={`${classes['trip-column-line']} ${classes['trip-column-line__header']} ${classes['trip-column-line__header__center']}`}>
            <div className={classes['trip-column-title']}>
              Стоимость
            </div>
          </div>
          {isWindowWidthMobile &&
            <div className={classes['trip-tripData-container']}>
              <div className={classes['trip-tripData']}>
                <div className={classes['trip-tripData-header']}>
                  <div className={classes['trip-tripData-header-title']}>Базовый</div>
                  <div className={classes['trip-tripData-header-separator']}></div>
                </div>
                <div className={classes['trip-tripData-info']}>
                  <div className={classes['trip-tripData-info-data']}>
                    <div className={classes['trip-tripData-info-data-title']}>
                    Взрослый
                    </div>
                    <div className={classes['trip-tripData-info-data-separator']}></div>
                    <div className={classes['trip-tripData-info-data-value']}>
                      {data.price.base ? `${parseInt(data.price.base)} Р` : '-'}
                    </div>
                  </div>
                  <div className={classes['trip-tripData-info-data']}>
                    <div className={classes['trip-tripData-info-data-title']}>
                      Детский
                    </div>
                    <div className={classes['trip-tripData-info-data-separator']}></div>
                    <div className={classes['trip-tripData-info-data-value']}>
                      {data.price.baseChildren ? `${parseInt(data.price.baseChildren)} Р` : '-'}
                    </div>
                  </div>
                  <div className={classes['trip-tripData-info-data']}>
                    <div className={classes['trip-tripData-info-data-title']}>
                      Мест
                    </div>
                    <div className={classes['trip-tripData-info-data-separator']}></div>
                    <div className={classes['trip-tripData-info-data-value']}>
                      {data.seatsAvailable.base}
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes['trip-tripData']}>
                <div className={classes['trip-tripData-header']}>
                  <div className={classes['trip-tripData-header-title']}>Комфорт</div>
                  <div className={classes['trip-tripData-header-separator']}></div>
                </div>
                <div className={classes['trip-tripData-info']}>
                  <div className={classes['trip-tripData-info-data']}>
                    <div className={classes['trip-tripData-info-data-title']}>
                    Взрослый
                    </div>
                    <div className={classes['trip-tripData-info-data-separator']}></div>
                    <div className={classes['trip-tripData-info-data-value']}>
                      {data.price.comfort ? `${parseInt(data.price.comfort)} Р` : '-'}
                    </div>
                  </div>
                  <div className={classes['trip-tripData-info-data']}>
                    <div className={classes['trip-tripData-info-data-title']}>
                      Детский
                    </div>
                    <div className={classes['trip-tripData-info-data-separator']}></div>
                    <div className={classes['trip-tripData-info-data-value']}>
                      {data.price.comfortChildren ? `${parseInt(data.price.comfortChildren)} Р` : '-'}
                    </div>
                  </div>
                  <div className={classes['trip-tripData-info-data']}>
                    <div className={classes['trip-tripData-info-data-title']}>
                      Мест
                    </div>
                    <div className={classes['trip-tripData-info-data-separator']}></div>
                    <div className={classes['trip-tripData-info-data-value']}>
                      {data.seatsAvailable.comfort}
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes['trip-tripData']}>
                <div className={classes['trip-tripData-header']}>
                  <div className={classes['trip-tripData-header-title']}>Багаж</div>
                  <div className={classes['trip-tripData-header-separator']}></div>
                  <div className={classes['trip-tripData-header-value']}>{parseInt(data.price.baggage)} Р</div>
                </div>
              </div>
            </div>
          }
          {!isWindowWidthMobile && 
            <div className={classes['trip-column-line']}>
              <div className={`${classes['trip-column-card']} ${classes['trip-column-card__secondary']}`}>
                <div className={classes['trip-column-card-column']}>
                  <div className={`${classes['trip-column-card-column-line']} ${classes['trip-column-card-column-line__head']}`}>
                    Базовый
                  </div>
                  <div className={classes['trip-column-card-separator']}></div>
                  <div className={`${classes['trip-column-card-column-line']} ${classes['trip-column-card-column-line__head']}`}>
                    Комфорт
                  </div>
                </div>
                <div className={classes['trip-column-card-column']}>
                  <div className={`${classes['trip-column-card-column-line']} ${classes['trip-column-card-column-line__subtitle']}`}>
                    Взрослый
                  </div>
                  <div className={classes['trip-column-card-column-line']}>
                    {data.price.base ? `${parseInt(data.price.base)} Р` : '-'}
                  </div>
                  <div className={classes['trip-column-card-separator']}></div>
                  <div className={classes['trip-column-card-column-line']}>
                    {data.price.comfort ? `${parseInt(data.price.comfort)} Р` : '-'}
                  </div>
                </div>
                <div className={classes['trip-column-card-column']}>
                  <div className={`${classes['trip-column-card-column-line']} ${classes['trip-column-card-column-line__subtitle']}`}>
                    Детский
                  </div>
                  <div className={classes['trip-column-card-column-line']}>
                    {data.price.baseChildren ? `${parseInt(data.price.baseChildren)} Р` : '-'}
                  </div>
                  <div className={classes['trip-column-card-separator']}></div>
                  <div className={classes['trip-column-card-column-line']}>
                    {data.price.comfortChildren ? `${parseInt(data.price.comfortChildren)} Р` : '-'}
                  </div>
                </div>
                <div className={classes['trip-column-card-column']}>
                  <div className={`${classes['trip-column-card-column-line']} ${classes['trip-column-card-column-line__subtitle']}`}>
                    Мест
                  </div>
                  <div className={classes['trip-column-card-column-line']}>
                    {data.seatsAvailable.base}
                  </div>
                  <div className={classes['trip-column-card-separator']}></div>
                  <div className={classes['trip-column-card-column-line']}>
                    {data.seatsAvailable.comfort}
                  </div>
                </div>
                <div className={classes['trip-column-card-column']}>
                  <div className={`${classes['trip-column-card-column-line']} ${classes['trip-column-card-column-line__subtitle']}`}>
                    Багаж
                  </div>
                  <div className={classes['trip-column-card-column-line']}>
                    {parseInt(data.price.baggage)} Р
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      {withButtonConfirm &&
        <Button
        className={classes['trip-buttonConfirm']}
        onClick={function(e) {
          searchTicket()
        }}
        >
          Оформить
        </Button>
      }
    </div>
  )
})

export default Trip