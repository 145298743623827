import React from 'react'
import classes from './ScheduleDescription.module.css'

const ScheduleDescription = ({titleFrom, titleTo, secondary, titleSecondary, background, children}) => {
  return (
    <div>
      {secondary
      ?
        <>
        <div className={classes['route-subtitle']}>
          <div className={classes['route-subtitle-bg']}>
            {background}
          </div>
          <div className={`${classes['route-subtitle-title']} ${classes['route-subtitle-title__secondary']}`}>{titleSecondary}</div>
        </div>
        <div className={classes['route-description']}>
          {children}
        </div>
        </>
      :
      <>
        <div className={classes['route-subtitle']}>
          <div className={classes['route-subtitle-bg']}>
            {background}
          </div>
          <div className={classes['route-subtitle-title']}>{titleFrom}</div>
          <div className={classes['route-subtitle-title']}>{titleTo}</div>
        </div>
        <div className={classes['route-description']}>
          {children}
        </div>
      </>
      }
    </div>
  )
}

export default ScheduleDescription