import { BrowserRouter } from "react-router-dom";
import { unstable_HistoryRouter as Router } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import NavBar from "./components/NavBar/NavBar";
import { ConfigProvider } from 'antd'
import ru_RU from 'antd/locale/ru_RU';
import moment from "moment";
import 'moment/locale/ru';
import { beige1, beige4, black, blue1, blue2, blue3, blue4 } from "./utils/consts";
import { getUserInfo } from "./http/user";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserType } from "./store/UserStore";
import Footer from "./components/Footer/Footer";
import { getDictionaries } from "./http/dictionary";
import { setDictionaries } from "./store/DictionaryStore";
import history from "./utils/history";
import { getSettingsList } from "./http/default";
import { setSettingInformationMessageText, setSettingOrderCreateEnabled, setSettingsList } from "./store/SettingsStore";

moment.locale("ru")

const App = () => {
  const settingsList = useSelector(state => state.settings._settingsList)
  const settingOrderCreateEnabled = useSelector(state => state.settings._orderCreateEnabled)
  const dispatch = useDispatch()

  const fetchIsUserAuthenticated = async () => {
    console.log('fetching...')
    try {
      const response = await getUserInfo()
      sessionStorage.setItem('isUserAuthenticated', response.code)
      dispatch(setUserType(response.data.roles))
      console.log('user authenticated!')
    } catch (e) {
      if (e.response.status === 403) {
        console.log('user not authenticated!')
        sessionStorage.setItem('isUserAuthenticated', 'false')
      }
      else {
        console.log(e)
      }
    } finally {
      console.log('fetching completed.')
    }
  }

  const fetchDictionaries = async () => {
    try {
      let {data} = await getDictionaries()
      dispatch(setDictionaries(data))
    } catch (e) {
      console.log(e)
    }
  }

  const fetchSettingsList = async () => {
    try {
      let {data} = await getSettingsList()
      dispatch(setSettingsList(data))
      dispatch(setSettingOrderCreateEnabled(data.length && data.find((item) => item['name'].includes('order.create.enabled')).value))
      dispatch(setSettingInformationMessageText(data.length && data.find((item) => item['name'].includes('order.create.disabled_message'))))
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const isUserAuthenticated = sessionStorage.getItem('isUserAuthenticated')
    if (!isUserAuthenticated) fetchIsUserAuthenticated()
    fetchDictionaries()
    fetchSettingsList()
  }, [])

  return (
    <Router history={history}>
      <ConfigProvider
        locale={ru_RU}
        theme={{
          components: {
            Input: {
              algorithm: true,
              colorBgBase: beige4,
            },
            DatePicker: {
              algorithm: true,
              colorBgBase: beige4,
              colorBgContainer: beige4,
              colorBgElevated: beige4,
              colorBgSpotlight: black,
              colorTextBase: blue1,
              cellHeight: '25px',
              borderRadius: '20px',
              colorTextHeading: blue1,
              cellActiveWithRangeBg: blue4,
            },
            Select: {
              algorithm: true,
              colorBgBase: beige4,
              colorBgContainer: beige4,
              colorBgElevated: beige4,
              colorTextBase: blue1,
              borderRadius: '20px',
              optionPadding: '10px 20px',
              fontSize: '23px,',
            },
            Segmented: {
              algorithm: true,
              borderRadius: '100px',
              paddingContentHorizontal: '50px',
              colorBgBase: beige1,
              colorBgTextActive: blue1,
              colorTextBase: beige4,
              itemHoverBg: blue2,
              itemSelectedBg: blue1,
            },
            Checkbox: {
              colorBgContainer: 'transparent',
              borderRadiusSM: '0px',
              colorBorder: blue3,
              colorPrimary: blue2,
              colorPrimaryHover: blue1,
            },
            Modal: {
              colorBgBase: blue1,
              colorTextHeading: blue1,
            },
            Divider: {
              colorSplit: blue3,
              colorTextHeading: blue1,
              textPaddingInline: '8px',
              lineWidth: '1px',
            }
          },
        }}
      >
        <NavBar/>
        <AppRouter/>
        <Footer/>
      </ConfigProvider>
    </Router>
  );
}

export default App;
