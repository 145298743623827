import React from 'react'
import logo from '../../assets/logo.svg'

const Logo = ({width, height, color, viewBox, className, logoRef}) => {
  return (
    <svg ref={logoRef} viewBox={viewBox} className={className}>
      <use xlinkHref={`${logo}#logo-${color}`} width={width} height={height}/>
    </svg>
  )
}

export default Logo