import { createSlice } from "@reduxjs/toolkit";

const DictionaryStore = createSlice({
  name: 'dictionary',
  initialState: {
    _countries: [],
    _dictionaries: {},
  },
  reducers: {
    setCountries(state, action) {
      state._countries = action.payload;
    },
    setDictionaries(state, action) {
      state._dictionaries = action.payload;
    }
  },
})

export const { setCountries, setDictionaries } = DictionaryStore.actions;

export default DictionaryStore.reducer;