import { ABOUT_ROUTE, ACCOUNT_ROUTE, AUTH_ROUTE, BOOKING_ROUTE, INFO_ROUTE, MAIN_ROUTE, NOTFOUND_ROUTE, OFFER_ROUTE, PASSENGERS_ROUTE, PERSONALDATAPROCESSING_ROUTE, PRIVACYPOLICY_ROUTE, REGISTRATION_ROUTE, TICKETS_ROUTE, TRIPS_ROUTE } from "./utils/consts";
import Main from './pages/Main/Main'
import Auth from './pages/Auth/Auth';
import Passengers from './pages/Passengers/Passengers';
import Trips from './pages/Trips/Trips';
import Booking from './pages/Booking/Booking';
import { lazy } from "react"; 

const Account = lazy(() => import('./pages/Account/Account'))
const NotFound = lazy(() => import('./pages/NotFound/NotFound'))
const Tickets = lazy(() => import('./pages/Tickets/Tickets'))
const Info = lazy(() => import('./pages/Info/Info'))
const About = lazy(() => import('./pages/About/About'))
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'))
const Offer = lazy(() => import('./pages/Offer/Offer'))
const PersonalDataProcessing = lazy(() => import('./pages/PersonalDataProcessing/PersonalDataProcessing'))

export const authRoutes = [
    {
        path: ACCOUNT_ROUTE,
        Component: Account
    },
]

export const publicRoutes = [
    {
        path: MAIN_ROUTE,
        Component: Main
    },
    {
        path: AUTH_ROUTE,
        Component: Auth
    },
    {
        path: REGISTRATION_ROUTE,
        Component: Auth
    },
    {
        path: ABOUT_ROUTE,
        Component: About
    },
    {
        path: INFO_ROUTE,
        Component: Info
    },
    {
        path: TRIPS_ROUTE,
        Component: Trips
    },
    {
        path: TICKETS_ROUTE,
        Component: Tickets
    },
    {
        path: BOOKING_ROUTE,
        Component: Booking
    },
    {
        path: PASSENGERS_ROUTE,
        Component: Passengers
    },
    {
        path: NOTFOUND_ROUTE,
        Component: NotFound
    },
    {
        path: PRIVACYPOLICY_ROUTE,
        Component: PrivacyPolicy
    },
    {
        path: OFFER_ROUTE,
        Component: Offer
    },
    {
        path: PERSONALDATAPROCESSING_ROUTE,
        Component: PersonalDataProcessing
    },

]