import React from 'react'
import classes from './ScheduleDescriptionLine.module.css'

const ScheduleDescriptionLine = ({name, record, withSubline = false, subline = false}) => {
  const lineClassNames = withSubline ? `${classes['route-description-line']} ${classes['route-description-line__withSubline']}` : subline ?  classes['route-description-subline'] : classes['route-description-line']
  const lineNameClassNames = subline ? classes['route-description-subline-name'] : classes['route-description-line-name']
  const lineRecordClassNames = subline ? classes['route-description-subline-record'] : classes['route-description-line-record']
  return (
    <div className={lineClassNames}>
      <div className={lineNameClassNames}>
        {name}
      </div>
      <div className={lineRecordClassNames}>
        {record}
      </div>
    </div>
  )
}

export default ScheduleDescriptionLine