import React from 'react'

const Auth = () => {
  return (
    <div>
        Регистрация / Авторизация
    </div>
  )
}

export default Auth