export const MAIN_ROUTE = '/'
export const AUTH_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const ACCOUNT_ROUTE = '/account'
export const ABOUT_ROUTE = '/about'
export const INFO_ROUTE = '/info'
export const TRIPS_ROUTE = '/trips'
export const TICKETS_ROUTE = '/tickets'
export const BOOKING_ROUTE = '/booking'
export const PASSENGERS_ROUTE = '/passengers'
export const NOTFOUND_ROUTE = '/notfound'
export const PRIVACYPOLICY_ROUTE = '/privacypolicy'
export const OFFER_ROUTE = '/offer'
export const PERSONALDATAPROCESSING_ROUTE = '/personaldataprocessing'
export const ADMIN_ROUTE = '/admin'

export const blue1 = '#274898';
export const blue2 = '#5B79C4';
export const blue3 = '#9AB0E5';
export const blue4 = '#E6EAFA';

export const yellow1 = '#BFB792';
export const yellow2 = '#DFD6AA';
export const yellow3 = '#FFF4C2';
export const yellow4 = '#FFFFDF';

export const beige1 = '#996A28';
export const beige2 = '#C5995B';
export const beige3 = '#E5C69A';
export const beige4 = '#FAF1E6';

export const white = '#fff';
export const black = '#000';
export const gray = '#565656';
export const darkGray = '#2e2e2e';
export const error = '#993b28';

export const windowWidthTablet = 834;
export const windowWidth750 = 760;
export const windowWidthMobile = 500;
export const windowWidthMobile2 = 350;

export const seatColorAvailable = beige4;
export const seatColorInProgress = beige1;
export const seatColorOccupied = blue1;
export const seatColorActiveBase = blue3;
export const seatColorActiveComfort = yellow1;

export const maximumPassengersInOrder = 6;
export const maximumPassengerBaggage = 3;