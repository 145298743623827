import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { authRoutes, publicRoutes } from '../routes'
import { NOTFOUND_ROUTE, REGISTRATION_ROUTE } from '../utils/consts';

const AppRouter = () => {
  const isAuth = false;
  return (
    <Routes>
      {isAuth && authRoutes.map(({path, Component}) => 
        <Route key={path} path={path} element={
          <Suspense>
            <Component/>
          </Suspense>
        }/>
      )}
      {publicRoutes.map(({path, Component}) => 
        <Route key={path} path={path} element={
          <Suspense>
            <Component/>
          </Suspense>
        }/>
      )}
      <Route path='account' element={<Navigate to={REGISTRATION_ROUTE}/>}/>
      <Route path='*' element={<Navigate to={NOTFOUND_ROUTE}/>}/>
    </Routes>
  )
}

export default AppRouter