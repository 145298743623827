import React, { useRef, useState } from 'react'
import Logo from '../../components/Logo/Logo'
import Wrapper from '../../components/Wrapper/Wrapper'
import banner from '../../images/compressed/banner.webp';
import bannerTablet from '../../images/banner-tablet.png';
import city1 from '../../images/compressed/cityNovorossiysk.webp';
import city1Early from '../../images/city1Early.jpg';
import city1Mid from '../../images/city1Mid.jpg';
import city1Late from '../../images/city1Late.jpg';
import city2 from '../../images/compressed/citySochi.webp';
import seatScheme from '../../images/seatScheme.svg';
import classes from './Main.module.css'
import H1 from '../../components/H1/H1';
import {Input, message, notification} from 'antd'
import {DatePicker, Segmented, Select} from 'antd'
import moment from 'moment';
import { Button } from '../../components/Button/Button';
import dayjs from 'dayjs';
import Schedule from '../../components/Schedule/Schedule';
import { getStops, getTrips } from '../../http/trip';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { setFromStopList, setSelectedDate, setSelectedFromStop, setSelectedToStop, setToStopList, setTripsList } from '../../store/TripsStore';
import Icons from '../../components/Icons/Icons';
import { BOOKING_ROUTE, MAIN_ROUTE, TRIPS_ROUTE, beige4, blue1, windowWidthMobile, windowWidthTablet } from '../../utils/consts';
import ScheduleDescription from '../../components/ScheduleDescription/ScheduleDescription';
import ScheduleDescriptionLine from '../../components/ScheduleDescriptionLine/ScheduleDescriptionLine';
import ShipScheme from '../../components/ShipScheme/ShipScheme';
import Image from '../../components/Image/Image';
import CollapseComponent from '../../components/Collapse/CollapseComponent';
import { HashLink } from 'react-router-hash-link';
import { isUrl } from '../../utils/isUrl';
import { setXHRisRunning } from '../../store/SettingsStore';

const Main = () => {
  const [segment, setSegment] = useState()
  const [selectFromStopStatus, setSelectFromStopStatus] = useState('normal')
  const [selectFromStopPlaceholder, setSelectFromStopPlaceholder] = useState('Откуда')
  const [selectToStopStatus, setSelectToStopStatus] = useState('normal')
  const [selectToStopPlaceholder, setSelectToStopPlaceholder] = useState('Куда')
  const [inputOrderNumberStatus, setInputOrderNumberStatus] = useState('normal')
  const [inputOrderNumberPlaceholder, setInputOrderNumberPlaceholder] = useState('Номер')
  const [orderNumber, setOrderNumber] = useState()
  const [inputLastNameStatus, setInputLastNameStatus] = useState('normal')
  const [inputLastNamePlaceholder, setInputLastNamePlaceholder] = useState('Фамилия')
  const [lastName, setLastName] = useState()
  const [cityFirst, setCityFirst] = useState(city1)
  const bannerImageRef = useRef(null)
  const logoImageRef = useRef(null)
  const [searchOrderButtonIsLoading, setSearchOrderButtonIsLoading] = useState(false)
  const [searchTripsButtonIsLoading, setSearchTripsButtonIsLoading] = useState(false)
  const [stopSelectIsLoading, setStopSelectIsLoading] = useState(false)
  const [isWindowWidthTablet, setIsWindowWidthTablet] = useState(window.innerWidth <= windowWidthTablet)
  const [isWindowWidthMobile, setIsWindowWidthMobile] = useState(window.innerWidth <= windowWidthMobile)
  const settingOrderCreateEnabled = useSelector(state => state.settings._orderCreateEnabled)
  const settingInformationMessageText = useSelector(state => state.settings._informationMessageText)
  const XHRisRunning = useSelector(state => state.settings._XHRisRunning)

  const selectedFromStop = useSelector(state => state.trips._selectedFromStop)
  const selectedToStop = useSelector(state => state.trips._selectedToStop)
  const fromStopList = useSelector(state => state.trips._fromStopList)
  const toStopList = useSelector(state => state.trips._toStopList)
  const selectedDate = useSelector(state => state.trips._selectedDate)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const datapicker = useRef()

  const [messageApi, contextHolder] = notification.useNotification()
  const returnMessage = (type, title, content, className, style, duration) => {
    messageApi.open({
      message: title,
      description: content,
      className: className,
      style: style,
      duration: duration,
      placement: 'top',
      closeIcon:
        <Icons
          name='x'
          size='24px'
          stroke={blue1}
        />,
    })
  }

  const fetchStops = async () => {
    try {
      dispatch(setXHRisRunning(true))
      setSearchTripsButtonIsLoading(true)
      setStopSelectIsLoading(true)
      let {data} = await getStops()
      console.log(data)
      dispatch(setFromStopList(data))
      dispatch(setToStopList(data))
    } catch (e) {
      console.log(e)
      returnMessage('error', e.response.status, e.response.data.message, '', '', 10)
    } finally {
      dispatch(setXHRisRunning(false))
      setSearchTripsButtonIsLoading(false)
      setStopSelectIsLoading(false)
    }
  }
  const fetchTrips = async () => {
    if (XHRisRunning) {
      return false
    }
    else {
      dispatch(setXHRisRunning(true))
      setSearchTripsButtonIsLoading(true)
      try {
        let {data} = await getTrips(selectedDate.format(), selectedFromStop, selectedToStop)
        console.log(data)
        dispatch(setTripsList(data))
      } catch (e) {
        console.log(e)
        returnMessage('error', e.response.status, e.response.data.message, '', '', 10)
      } finally {
        dispatch(setXHRisRunning(false))
        setSearchTripsButtonIsLoading(false)
        navigate({
          pathname: TRIPS_ROUTE,
          search: `?${createSearchParams({
            date: selectedDate.format(),
            fromStop: selectedFromStop,
            toStop: selectedToStop,
          })}`
        })
      }
    }
  }

  const switchStops = () => {
    if (selectedFromStop == null || selectedToStop == null) {
      return false
    }
    let fromStopTemp = selectedFromStop
    dispatch(setSelectedFromStop(selectedToStop))
    dispatch(setSelectedToStop(fromStopTemp))
    console.log({selectedFromStop, selectedToStop})
  }

  const changeSelectedStopValue = (selectedTypeValue, setSelectedTypeValue, setSelectedTypeStatus, value) => {
    if (selectedTypeValue === value) {
      returnMessage('error', 'Ошибка', `Нельзя выбрать один и тот же город как пункт отправления и как пункт прибытия`, '', '', 10)
    }
    else {
      dispatch(setSelectedTypeValue(value))
      setSelectedTypeStatus('ok')
    }
  }

  const searchTrips = () => {
    if (!selectedFromStop) {
      setSelectFromStopStatus('error')
      setSelectFromStopPlaceholder('Выберите вариант')
      returnMessage('error', 'Ошибка', `Выберите пункт отправления`, '', '', 10)
    }
    if (!selectedToStop) {
      setSelectToStopStatus('error')
      setSelectToStopPlaceholder('Выберите вариант')
      returnMessage('error', 'Ошибка', `Выберите пункт прибытия`, '', '', 10)
    }
    if (!selectedDate) {
      returnMessage('error', 'Ошибка', `Выберите дату отправления`, '', '', 10)
    }
    if (!(selectedFromStop == null || selectedToStop == null || selectedDate == null)) {
      fetchTrips()
    }
  }

  const searchOrder = () => {
    if (XHRisRunning) {
      return false
    }
    else {
      setSearchOrderButtonIsLoading(true)
      if (orderNumber == '' || orderNumber == null) {
        setInputOrderNumberStatus('error')
        setInputOrderNumberPlaceholder('Введите номер')
        returnMessage('error', 'Ошибка', `Введите номер бронирования`, '', '', 10)
      }
      if (lastName == '' || lastName == null) {
        setInputLastNameStatus('error')
        setInputLastNamePlaceholder('Введите фамилию')
        returnMessage('error', 'Ошибка', `Введите фамилию`, '', '', 10)
      }
      if (!(orderNumber == '' || orderNumber == null
          ||lastName == '' || lastName == null
      )) {
        navigate({
          pathname: BOOKING_ROUTE,
          search: `?${createSearchParams({
            orderNumber: orderNumber,
            lastName: lastName,
          })}`
        })
      }
      setSearchOrderButtonIsLoading(false)
    }
  }
  
  const disabledDate = (current) => {
    return current < (dayjs().add(-1, 'day').endOf('day')) 
  }

  const changeCitiesImagesDependingOnDayTime = () => {
    const currentTime = moment(new Date()).format('HH:mm')
    if (currentTime <= '12:00') {
      setCityFirst(city1Early)
    }
    else if (currentTime > '12:00' && currentTime <= '16:00') {
      setCityFirst(city1Mid)
    }
    else if (currentTime > '16:00') {
      setCityFirst(city1Late)
    }
  }

  useEffect(() => {
    if (!(fromStopList.length > 0) || !(toStopList.length > 0)) {
      fetchStops()
    }
    // changeCitiesImagesDependingOnDayTime()
  }, [])

  const onWindowWidthChange = () => {
    if (bannerImageRef.current) bannerImageRef.current.src = window.innerWidth <= windowWidthTablet ? bannerTablet : banner
    setIsWindowWidthTablet(window.innerWidth <= windowWidthTablet)
    setIsWindowWidthMobile(window.innerWidth <= windowWidthMobile)
  }
  useEffect(() => {
    window.addEventListener('resize', onWindowWidthChange);
    return () => window.removeEventListener('resize', onWindowWidthChange);
  }, [])

  const [hoverImage, setHoverImage] = useState(false)
  return (
    <Wrapper>
      {contextHolder}
      {settingOrderCreateEnabled.toString() === 'false' &&
        <div className={classes['announce-container']}>
          <div className={classes['announce']}>
            <div className={`${classes['announce-column']} ${classes['announce-price']}`}>
              <div className={`${classes['announce-column-line']} ${classes['announce-column-line__header']}`}>
                <div className={classes['announce-column-title']}>
                  Объявление!
                </div>
              </div>
              <div className={classes['announce-column-line']}>
                <div className={`${classes['announce-column-card']}`}>
                  <div className={`${classes['announce-text']}`}>
                    {settingInformationMessageText.value.split(/[\r\n]+/).map((line, index) =>
                      <p key={index}>
                        {line.split(' ').map((word, index) => 
                          isUrl(word) !== null ? <Link className={classes['announce-link']} to={word} key={index}>{`${word} `}</Link> : `${word} ` 
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div className={classes.firstScreen} id='firstScreen'>
        <div className={classes['firstScreen-block']}>
          {!isWindowWidthMobile &&      
          <>
            <Logo className={classes.logo} logoRef={logoImageRef} color='blue' width={isWindowWidthTablet ? '30%' : '15%'} height={isWindowWidthTablet ? '30%' : '15%'} viewBox={isWindowWidthTablet ? '0 0 210 76' : '0 0 232 84'}/>
            <img className={classes.banner} ref={bannerImageRef} key={isWindowWidthTablet ? bannerTablet : banner} src={isWindowWidthTablet ? bannerTablet : banner} alt='Баннер сайта для заказа билетов' width='100%' height='100%'/>
          </>    
          }
          <div className={classes.tickets}>
            {!isWindowWidthMobile &&
              <Segmented
                options={[
                  { label: 'Покупка билета', value: 'buy',},
                  { label: 'Управление бронью', value: 'reservation'},
                ]}
                defaultValue='buy'
                onChange={(value) => setSegment(value)}
                className={classes['tickets-tabChanger']}
                block='true'
              />
            }
            <div className={classes['tickets-container']}>
              {isWindowWidthMobile &&
                <>
                  <CollapseComponent
                    isWindowWidthMobile={isWindowWidthMobile}
                    className={classes['buyTicketCollapse']}
                    iconContainerClassName={classes['buyTicketCollapse-iconContainer']}
                    accordion={true}
                    activeKey={segment || 'buy'}
                    defaultActiveKey={['buy']}
                    expandIcon={
                      <Icons
                        name='arrow-left'
                        size='24px'
                        stroke={beige4}
                      />
                    }
                    rotateFrom='90deg'
                    rotateTo='-90deg'
                    setActiveKey={setSegment}
                    items={[
                      {
                        key: 'buy',
                        label: 'Покупка билета',
                        children: 
                        <>
                        <div className={classes['tickets-column']}>
                          <DatePicker
                            ref={datapicker}
                            className={`${classes['tickets-datePick']} ${'tickets-dataPick'}`}
                            popupClassName={classes['tickets-datePick-popup']}
                            maxDate={moment('2030-01-01')}
                            minDate={moment().add(-1, "days")}
                            disabledDate={disabledDate}
                            defaultOpenValue='true'
                            defaultOpen={true}
                            defaultValue={selectedDate}
                            hidden={false}
                            open={true}
                            placement='bottomLeft'
                            placeholder='Выберите дату'
                            onCalendarChange={(value) => dispatch(setSelectedDate(dayjs(value)))}
                            getPopupContainer={trigger => trigger.parentElement}
                          />
                        </div>
                        <div className={classes['tickets-column']}>
                          <Input
                            className={classes['tickets-dateInput']}
                            value={`Дата   ${selectedDate.format('DD.MM.YYYY')}`}
                            readOnly={true}
                          />
                          <div className={classes['tickets-route-title']}>Направление</div>
                          <div className={classes['tickets-route-container']}>
                            <Select
                              className={`${classes['tickets-route-container-select']} ${'tickets-route-container-select'}`}
                              showSearch
                              defaultValue={selectedFromStop}
                              value={selectedFromStop}
                              placeholder={selectFromStopPlaceholder}
                              label="true"
                              optionFilterProp='label'
                              onChange={function(value) {
                                changeSelectedStopValue(selectedToStop, setSelectedFromStop, setSelectFromStopStatus, value)
                              }}
                              status={selectFromStopStatus}
                            >
                              {fromStopList.map(stop => 
                                <Select.Option value={stop.id} key={stop.id} label={stop.name}>{stop.name}</Select.Option>
                              )}
                            </Select>
                            <Select
                              className={`${classes['tickets-route-container-select']} ${'tickets-route-container-select'}`}
                              showSearch
                              defaultValue={selectedToStop}
                              value={selectedToStop}
                              placeholder={selectToStopPlaceholder}
                              label="true"
                              optionFilterProp='label'
                              onChange={function(value) {
                                changeSelectedStopValue(selectedFromStop, setSelectedToStop, setSelectToStopStatus, value)
                              }}
                              status={selectToStopStatus}
                            >
                              {toStopList.map(stop => 
                                <Select.Option value={stop.id} key={stop.id} label={stop.name}>{stop.name}</Select.Option>
                              )}
                            </Select>
                            <button style={{position: 'absolute', right: '0', display: 'flex', alignItems: 'center'}} onClick={() => switchStops()}>
                              <Icons
                                name='switch__primary'
                                size='24px'
                                className={classes['tickets-route-container-icon']} 
                              />
                            </button> 
                          </div>
                        </div>
                      </>
                      },
                      {
                        key: 'reservation',
                        label: 'Управление бронью',
                        children: 
                          <div style={{display: 'flex', flexDirection: 'column', rowGap: '30px'}}>
                            <div className={classes['tickets-column']}>
                              <div className={classes['tickets-dataDescription']}>Номер брони</div>
                              <div className={classes['tickets-input-container']}>
                                <Input
                                  className={classes['tickets-input']}
                                  value={orderNumber}
                                  placeholder={inputOrderNumberPlaceholder}
                                  onChange={function(e) {
                                    setOrderNumber(e.target.value)
                                    setInputOrderNumberStatus('ok')
                                  }}
                                  status={inputOrderNumberStatus}
                                />
                                <div className={classes['tickets-input-example']}>Например, 20240415-123456</div>
                              </div>
                            </div>
                            <div className={classes['tickets-column']}>
                              <div className={classes['tickets-dataDescription']}>Фамилия</div>
                              <div className={classes['tickets-input-container']}>
                                <Input
                                  className={classes['tickets-input']}
                                  value={lastName}
                                  placeholder={inputLastNamePlaceholder}
                                  onChange={function(e) {
                                    setLastName(e.target.value.toString().toUpperCase())
                                    setInputLastNameStatus('ok')
                                  }}
                                  status={inputLastNameStatus}
                                />
                                <div className={classes['tickets-input-example']}>Например, Иванов</div>
                              </div>
                            </div>
                            <div className={classes['tickets-column-description']}>
                              Управление бронированием ваших билетов, отслеживание статуса рейса.
                            </div>
                          </div>
                      },
                    ]}
                    headerBackground={<div></div>}
                    isWindowWidthTablet={false}
                  />
                  {segment === 'reservation' &&
                    <Button
                      onClick={function(e) {
                        searchOrder()
                      }}
                      className={`${classes['tickets-button__confirm']} ${classes['tickets-button__reservation']}`}
                    >
                      Поиск
                    </Button>
                  }
                  {(segment === 'buy' || segment !== 'reservation') &&
                    <Button
                      onClick={function(e) {
                        searchTrips()
                      }}
                      className={`${classes['tickets-button__confirm']} ${classes['tickets-button__buy']}`}
                    >
                      Найти
                    </Button>
                  }
                </>
              }
              {!isWindowWidthMobile && segment === 'reservation'
              &&
                <>
                  <div className={classes['tickets-column']}>
                    <div className={classes['tickets-dataDescription']}>Номер брони</div>
                    <div className={classes['tickets-input-container']}>
                      <Input
                        className={classes['tickets-input']}
                        value={orderNumber}
                        placeholder={inputOrderNumberPlaceholder}
                        onChange={function(e) {
                          setOrderNumber(e.target.value)
                          setInputOrderNumberStatus('ok')
                        }}
                        status={inputOrderNumberStatus}
                      />
                      <div className={classes['tickets-input-example']}>Например, 20240415-123456</div>
                    </div>
                  </div>
                  <div className={classes['tickets-column']}>
                    <div className={classes['tickets-dataDescription']}>Фамилия</div>
                    <div className={classes['tickets-input-container']}>
                      <Input
                        className={classes['tickets-input']}
                        value={lastName}
                        placeholder={inputLastNamePlaceholder}
                        onChange={function(e) {
                          setLastName(e.target.value.toString().toUpperCase())
                          setInputLastNameStatus('ok')
                        }}
                        status={inputLastNameStatus}
                      />
                      <div className={classes['tickets-input-example']}>Например, Иванов</div>
                    </div>
                  </div>
                  <div className={classes['tickets-column-description']}>
                    Управление бронированием ваших билетов, отслеживание статуса рейса.
                  </div>
                </>
              }
              {!isWindowWidthMobile && segment !== 'reservation' &&
                <>
                  <div className={classes['tickets-column']}>
                    <DatePicker
                      ref={datapicker}
                      className={`${classes['tickets-datePick']} ${'tickets-dataPick'}`}
                      popupClassName={classes['tickets-datePick-popup']}
                      maxDate={moment('2030-01-01')}
                      minDate={moment().add(-1, "days")}
                      disabledDate={disabledDate}
                      defaultOpenValue='true'
                      defaultOpen={true}
                      defaultValue={selectedDate}
                      hidden={false}
                      open={true}
                      placement='bottomLeft'
                      placeholder='Выберите дату'
                      onCalendarChange={(value) => dispatch(setSelectedDate(dayjs(value)))}
                      getPopupContainer={trigger => trigger.parentElement}
                    />
                  </div>
                  <div className={classes['tickets-column']}>
                    <Input
                      className={classes['tickets-dateInput']}
                      value={`Дата   ${selectedDate.format('DD.MM.YYYY')}`}
                      readOnly={true}
                    />
                    <div className={classes['tickets-route-title']}>Направление</div>
                    <div className={classes['tickets-route-container']}>
                      <Select
                        className={`${classes['tickets-route-container-select']} ${'tickets-route-container-select'}`}
                        showSearch
                        disabled={stopSelectIsLoading}
                        defaultValue={selectedFromStop}
                        value={selectedFromStop}
                        placeholder={selectFromStopPlaceholder}
                        label="true"
                        optionFilterProp='label'
                        onChange={function(value) {
                          changeSelectedStopValue(selectedToStop, setSelectedFromStop, setSelectFromStopStatus, value)
                        }}
                        status={selectFromStopStatus}
                      >
                        {fromStopList.map(stop => 
                          <Select.Option value={stop.id} key={stop.id} label={stop.name}>{stop.name}</Select.Option>
                        )}
                      </Select>
                      <Select
                        className={`${classes['tickets-route-container-select']} ${'tickets-route-container-select'}`}
                        showSearch
                        disabled={stopSelectIsLoading}
                        defaultValue={selectedToStop}
                        value={selectedToStop}
                        placeholder={selectToStopPlaceholder}
                        label="true"
                        optionFilterProp='label'
                        onChange={function(value) {
                          changeSelectedStopValue(selectedFromStop, setSelectedToStop, setSelectToStopStatus, value)
                        }}
                        status={selectToStopStatus}
                      >
                        {toStopList.map(stop => 
                          <Select.Option value={stop.id} key={stop.id} label={stop.name}>{stop.name}</Select.Option>
                        )}
                      </Select>
                      <button style={{position: 'absolute', right: '0', display: 'flex', alignItems: 'center'}} onClick={() => switchStops()}>
                        <Icons
                          name='switch__primary'
                          size='24px'
                          className={classes['tickets-route-container-icon']} 
                        />
                      </button> 
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
        {!isWindowWidthMobile && segment === 'reservation' &&
            <Button
              loading={searchOrderButtonIsLoading}
              onClick={function(e) {
                searchOrder()
              }}
              className={classes['tickets-button__confirm']}
            >
              Поиск
            </Button>
        }
        {!isWindowWidthMobile && segment !== 'reservation' &&
            <Button
              loading={searchTripsButtonIsLoading}
              onClick={function(e) {
                searchTrips()
              }}
              className={classes['tickets-button__confirm']}
            >
              Найти
            </Button>
        }
      </div>
      <div className={classes.schedule} id='schedule'>
        <H1>Расписание и тарифы</H1>
        <div className={classes['schedule-routes']}>
          <Schedule>
            <ScheduleDescription
              titleFrom='Новороссийск'
              titleTo='Сочи'
              background={
                <>
                <svg className={classes['route-subtitle-bg']} width="432" height="57" viewBox="0 0 432 57" fill="none" xmlns="http:www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0 28C0 12.536 12.536 0 28 0H221C230.829 0 239.476 5.06499 244.472 12.7274C248.277 18.5628 253.89 24 260.856 24H281.144C288.11 24 293.723 18.5628 297.528 12.7274C302.524 5.06499 311.171 0 321 0H404C419.464 0 432 12.536 432 28C432 43.464 419.464 56 404 56H321C311.171 56 302.524 50.935 297.528 43.2726C293.723 37.4372 288.11 32 281.144 32H260.856C253.89 32 248.277 37.4372 244.472 43.2726C239.476 50.935 230.829 56 221 56H28C12.536 56 0 43.464 0 28Z" fill="#274898"/>
                </svg>
                </>
              }
            >
              <ScheduleDescriptionLine name='Отправление' record='08:00'/>
              <ScheduleDescriptionLine name='Прибытие' record='11:30'/>
              <ScheduleDescriptionLine name='Время в пути' record='03:30 ч.'/>
            </ScheduleDescription>
            <ScheduleDescription
              secondary={true}
              titleSecondary='Стоимость'
              background={
                <>
                  {window.innerWidth >= windowWidthTablet && 
                  <svg className={classes['route-subtitle-bg']} width="628" height="56" viewBox="0 0 628 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 28C0 12.536 12.536 0 28 0H180C202.937 0 224.752 24 247.688 24H582.643C593.284 24 603.359 14 614 14C621.732 14 628 20.268 628 28C628 35.732 621.732 42 614 42C603.359 42 593.284 32 582.643 32H247.688C224.752 32 202.937 56 180 56H28C12.536 56 0 43.464 0 28Z" fill="#9AB0E5"/>
                  </svg>
                  }
                </>
              }
            >
              <ScheduleDescriptionLine name='Базовый' withSubline={true}/>
              <ScheduleDescriptionLine name='Взрослый' record='4000 ₽' subline={true}/>
              <ScheduleDescriptionLine name='Дети до 14 лет' record='3200 ₽' subline={true}/>
              <ScheduleDescriptionLine name='Комфорт' withSubline={true}/>
              <ScheduleDescriptionLine name='Взрослый' record='5000 ₽' subline={true}/>
              <ScheduleDescriptionLine name='Дети до 14 лет' record='4000 ₽' subline={true}/>
            </ScheduleDescription>
            <HashLink
              to={`${MAIN_ROUTE}\#firstScreen`}
              scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
            >
              <Button
                className={classes['route-button']}
              >
                Купить
              </Button>
            </HashLink>
          </Schedule>
          <div className={classes['schedule-routesSeparator']}></div>
          <Schedule>
            <ScheduleDescription
              titleFrom='Сочи'
              titleTo='Новороссийск'
              background={
                <>
                <svg className={classes['route-subtitle-bg']} width="432" height="57" viewBox="0 0 432 57" fill="none" xmlns="http:www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M432 28C432 43.464 419.464 56 404 56L211 56C201.171 56 192.524 50.935 187.528 43.2726C183.723 37.4372 178.11 32 171.144 32L150.856 32C143.89 32 138.277 37.4372 134.472 43.2726C129.476 50.935 120.829 56 111 56L28 56C12.536 56 1.09593e-06 43.4639 2.44784e-06 28C3.79974e-06 12.536 12.536 -3.66707e-05 28 -3.53188e-05L111 -2.80627e-05C120.829 -2.72034e-05 129.476 5.06496 134.472 12.7274C138.277 18.5628 143.89 24 150.856 24L171.144 24C178.11 24 183.723 18.5628 187.528 12.7274C192.524 5.06497 201.171 -2.01798e-05 211 -1.93204e-05L404 -2.44784e-06C419.464 -1.09593e-06 432 12.536 432 28Z" fill="#274898"/>
                </svg>
                </>
              }
            >
              <ScheduleDescriptionLine name='Отправление' record='16:30'/>
              <ScheduleDescriptionLine name='Прибытие' record='19:30'/>
              <ScheduleDescriptionLine name='Время в пути' record='03:00 ч.'/>
            </ScheduleDescription>
            <ScheduleDescription
              secondary={true}
              titleSecondary='Стоимость'
              background={
                <>
                {window.innerWidth >= windowWidthTablet &&
                  <svg className={classes['route-subtitle-bg']} width="628" height="56" viewBox="0 0 628 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 28C0 12.536 12.536 0 28 0H180C202.937 0 224.752 24 247.688 24H582.643C593.284 24 603.359 14 614 14C621.732 14 628 20.268 628 28C628 35.732 621.732 42 614 42C603.359 42 593.284 32 582.643 32H247.688C224.752 32 202.937 56 180 56H28C12.536 56 0 43.464 0 28Z" fill="#9AB0E5"/>
                  </svg>
                }
                </>
              }
            >
              <ScheduleDescriptionLine name='Базовый' withSubline={true}/>
              <ScheduleDescriptionLine name='Взрослый' record='4000 ₽' subline={true}/>
              <ScheduleDescriptionLine name='Дети до 14 лет' record='3200 ₽' subline={true}/>
              <ScheduleDescriptionLine name='Комфорт' withSubline={true}/>
              <ScheduleDescriptionLine name='Взрослый' record='5000 ₽' subline={true}/>
              <ScheduleDescriptionLine name='Дети до 14 лет' record='4000 ₽' subline={true}/>
            </ScheduleDescription>
            <HashLink
              to={`${MAIN_ROUTE}\#firstScreen`}
              scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
            >
              <Button className={classes['route-button']}>
                Купить
              </Button>
            </HashLink>
          </Schedule>
        </div>
      </div>
      <div className={classes.directions}>
        <H1>Направления</H1>
        <div className={classes['directions-routes']} style={{gridTemplateColumns: isWindowWidthMobile ? 'none' : window.innerWidth >= windowWidthTablet ?  hoverImage == '1' ? '60% 38%' : hoverImage == '2' ? '38% 60%' : '49% 49%' : hoverImage == '1' ? '49% 49%' : hoverImage == '2' ? '49% 49%' : '49% 49%'}}>
          <div className={classes.direction}
            onMouseEnter={() => setHoverImage('1')}
            onMouseLeave={() => setHoverImage(false)}
          >
            <Schedule maxWidth='100%'>
              <Image
                className={hoverImage == '1' ? `${classes['direction-image']} ${classes['direction-image__hover']}` : hoverImage == '2' ? `${classes['direction-image']} ${classes['direction-image__squize']}` : classes['direction-image']}
                loading='lazy'
                src={cityFirst}
                alt='Изображение города прибытия'
                width='100%'
                height='100%'
              />
              <ScheduleDescription
                titleFrom='Новороссийск'
                titleTo='Сочи'
                background={
                  <>
                  <svg className={classes['route-subtitle-bg']} width="432" height="56" viewBox="0 0 432 56" fill="none" xmlns="http:www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 28C0 12.536 12.536 0 28 0H221C230.829 0 239.476 5.06499 244.472 12.7274C248.277 18.5628 253.89 24 260.856 24H281.144C288.11 24 293.723 18.5628 297.528 12.7274C302.524 5.06499 311.171 0 321 0H404C419.464 0 432 12.536 432 28C432 43.464 419.464 56 404 56H321C311.171 56 302.524 50.935 297.528 43.2726C293.723 37.4372 288.11 32 281.144 32H260.856C253.89 32 248.277 37.4372 244.472 43.2726C239.476 50.935 230.829 56 221 56H28C12.536 56 0 43.464 0 28Z" fill="#274898"/>
                  </svg>
                  </>
                }
              >
                <div className={classes['direction-description']}>
                </div>
              </ScheduleDescription>
            </Schedule>
          </div>
          <div className={classes.direction}
            onMouseEnter={() => setHoverImage('2')}
            onMouseLeave={() => setHoverImage(false)}
          >
            <Schedule maxWidth='100%'>
              <Image
                className={hoverImage == '2' ? `${classes['direction-image']} ${classes['direction-image__hover']}` : hoverImage == '1' ? `${classes['direction-image']} ${classes['direction-image__squize']}` : classes['direction-image']}
                loading='lazy'
                src={city2}
                alt='Изображение города прибытия'
                width='100%'
                height='100%'
              />
              <ScheduleDescription
                titleFrom='Сочи'
                titleTo='Новороссийск'
                background={
                  <>
                  <svg className={classes['route-subtitle-bg']} width="432" height="56" viewBox="0 0 432 56" fill="none" xmlns="http:www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M432 28C432 43.464 419.464 56 404 56L211 56C201.171 56 192.524 50.935 187.528 43.2726C183.723 37.4372 178.11 32 171.144 32L150.856 32C143.89 32 138.277 37.4372 134.472 43.2726C129.476 50.935 120.829 56 111 56L28 56C12.536 56 1.09593e-06 43.4639 2.44784e-06 28C3.79974e-06 12.536 12.536 -3.66707e-05 28 -3.53188e-05L111 -2.80627e-05C120.829 -2.72034e-05 129.476 5.06496 134.472 12.7274C138.277 18.5628 143.89 24 150.856 24L171.144 24C178.11 24 183.723 18.5628 187.528 12.7274C192.524 5.06497 201.171 -2.01798e-05 211 -1.93204e-05L404 -2.44784e-06C419.464 -1.09593e-06 432 12.536 432 28Z" fill="#274898"/>
                  </svg>
                  </>
                }
              >
                <div className={classes['direction-description']}>
              </div>
              </ScheduleDescription>
            </Schedule>
          </div>
        </div>
      </div>
      <div className={classes.scheme}>
        <H1>Схема</H1>
        <ShipScheme width='100%' height='100%' viewBox='0 0 1552 544'/>
        <img className={classes['scheme-seatScheme']} src={seatScheme} alt='Схема расположения мест в судне'/>
      </div>
      <div className={classes.questions} id='questions'>
        <H1>Часто задаваемые вопросы</H1>
        <div className={classes['questions-columns-container']}>
          <div className={`${classes['questions-columns']} ${classes['questions-columns__two']}`}>
            <div className={classes['questions-column']}>
              <CollapseComponent
                className={classes['collapse-question']}
                iconContainerClassName={classes['collapse-question-iconContainer']}
                hasPseudos={true}
                text='При посадке на борт обязательно с собой иметь оригинал документа, удостоверяющего личность - паспорт или свидетельство о рождении, которые были использованы при оформлении билетов.'
                label='Какие документы необходимы для поездки?'
                headerBackground={
                  <svg width="759" height="55" viewBox="0 0 759 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 26C0 11.6406 11.6406 0 26 0H569C577.762 0 585.511 4.33386 590.222 10.9748C594.218 16.6086 599.791 22 606.698 22H695.302C702.209 22 707.782 16.6086 711.778 10.9748C716.489 4.33386 724.238 0 733 0C747.359 0 759 11.6406 759 26C759 40.3594 747.359 52 733 52C724.238 52 716.488 47.6656 711.778 41.0239C707.782 35.3893 702.207 29.997 695.3 29.997H606.7C599.793 29.997 594.218 35.3893 590.222 41.0239C585.512 47.6656 577.762 52 569 52H26C11.6406 52 0 40.3594 0 26Z" fill={blue1}/>
                  </svg>
                }
                isWindowWidthTablet={isWindowWidthTablet}
              />
              <CollapseComponent
                className={classes['collapse-question']}
                iconContainerClassName={classes['collapse-question-iconContainer']}
                hasPseudos={true}
                text={
                  <div style={{display: 'flex', flexDirection: 'column', rowGap: '8px'}}>
                    <p>
                      На указанную Вами почту после оплаты придет билет, необходимый для доступа на судна.
                    </p>
                    <p>
                      Номер телефона необходим для оперативной связи с Вами в случае непредвиденных обстоятельств.
                    </p>
                  </div>
                }
                label='Зачем указывать свой номер телефона и e-mail?'
                headerBackground={
                  <svg width="759" height="55" viewBox="0 0 759 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 26C0 11.6406 11.6406 0 26 0H610C618.762 0 626.511 4.33386 631.222 10.9748C635.218 16.6086 640.791 22 647.698 22H695.302C702.209 22 707.782 16.6086 711.778 10.9748C716.489 4.33386 724.238 0 733 0C747.359 0 759 11.6406 759 26C759 40.3594 747.359 52 733 52C724.238 52 716.488 47.6656 711.778 41.0239C707.781 35.3893 702.207 29.997 695.3 29.997H647.7C640.793 29.997 635.219 35.3893 631.222 41.0239C626.512 47.6656 618.762 52 610 52H26C11.6406 52 0 40.3594 0 26Z" fill={blue1}/>
                  </svg>
                }
                isWindowWidthTablet={isWindowWidthTablet}
              />
              <CollapseComponent
                className={classes['collapse-question']}
                iconContainerClassName={classes['collapse-question-iconContainer']}
                hasPseudos={true}
                text={'В стоимость билета включена ручная кладь весом не более 10 кг. при габаритных параметрах не более 55х40х25 см. Вы можете оформить дополнительное место багажа при бронировании билета. '}
                label='Какой багаж допустимо брать с собой на борт?'
                headerBackground={
                  <svg width="759" height="55" viewBox="0 0 759 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 26C0 11.6406 11.6406 0 26 0H607C615.762 0 623.511 4.33386 628.222 10.9748C632.218 16.6086 637.791 22 644.698 22H695.302C702.209 22 707.782 16.6086 711.778 10.9748C716.489 4.33386 724.238 0 733 0C747.359 0 759 11.6406 759 26C759 40.3594 747.359 52 733 52C724.238 52 716.488 47.6656 711.778 41.0239C707.781 35.3893 702.207 29.997 695.3 29.997H644.7C637.793 29.997 632.219 35.3893 628.222 41.0239C623.512 47.6656 615.762 52 607 52H26C11.6406 52 0 40.3594 0 26Z" fill={blue1}/>
                  </svg>
                }
                isWindowWidthTablet={isWindowWidthTablet}
              />
            </div>
            <div className={classes['questions-column']}>
              <CollapseComponent
                className={classes['collapse-question']}
                iconContainerClassName={classes['collapse-question-iconContainer']}
                hasPseudos={true}
                text={
                  <div style={{display: 'flex', flexDirection: 'column', rowGap: '8px'}}>
                    <p>
                      Возврат билета можно оформить на сайте через раздел "Управление бронированием".
                    </p>
                    <p>
                      Возврат билетов осуществляется согласно законодательству РФ и 
                      <Link
                        className={classes['infoAboutCompany-column-body-links-container']}
                        to='/offer'
                        target='_blank'
                      >
                        оферты
                      </Link>
                      .
                    </p>
                  </div>
                }
                label='Как вернуть деньги за билет?'
                headerBackground={
                  <svg width="759" height="54" viewBox="0 0 759 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 26C0 11.6406 11.6406 0 26 0H401C409.762 0 417.511 4.33386 422.222 10.9748C426.218 16.6086 431.791 22 438.698 22H695.302C702.209 22 707.782 16.6086 711.778 10.9748C716.489 4.33386 724.238 0 733 0C747.359 0 759 11.6406 759 26C759 40.3594 747.359 52 733 52C724.238 52 716.488 47.6656 711.778 41.0239C707.781 35.3893 702.207 29.997 695.3 29.997H438.7C431.793 29.997 426.219 35.3893 422.222 41.0238C417.512 47.6656 409.762 52 401 52H26C11.6406 52 0 40.3594 0 26Z" fill={blue1}/>
                  </svg>
                }
                isWindowWidthTablet={isWindowWidthTablet}
              />
              <CollapseComponent
                className={classes['collapse-question']}
                iconContainerClassName={classes['collapse-question-iconContainer']}
                hasPseudos={true}
                text={
                  <div style={{display: 'flex', flexDirection: 'column', rowGap: '8px'}}>
                    <p>
                      Вероятно, вы допустили ошибку при заполнении данных банковской карты - проверьте корректность введенных данных.
                    </p>
                    <p>
                      Если ошибка не устраняется, обратитесь в службу поддержки своего банка.
                    </p>
                  </div>
                }
                label='Что делать, если при оплате появляется ошибка?'
                headerBackground={
                  <svg width="759" height="55" viewBox="0 0 759 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 26C0 11.6406 11.6406 0 26 0H626C634.762 0 642.511 4.33386 647.222 10.9748C651.218 16.6086 656.791 22 663.698 22H695.302C702.209 22 707.782 16.6086 711.778 10.9748C716.489 4.33386 724.238 0 733 0C747.359 0 759 11.6406 759 26C759 40.3594 747.359 52 733 52C724.238 52 716.488 47.6656 711.778 41.0239C707.781 35.3893 702.207 29.997 695.3 29.997H663.7C656.793 29.997 651.219 35.3893 647.222 41.0239C642.512 47.6656 634.762 52 626 52H26C11.6406 52 0 40.3594 0 26Z" fill={blue1}/>
                  </svg>
                }
                isWindowWidthTablet={isWindowWidthTablet}
              />
              <CollapseComponent
                className={classes['collapse-question']}
                iconContainerClassName={classes['collapse-question-iconContainer']}
                hasPseudos={true}
                text={
                  <div style={{display: 'flex', flexDirection: 'column', rowGap: '8px'}}>
                    <p>
                      Допустим провоз животных в специально оборудованных переносных кейсах размером не более размеров ручной клади.
                    </p>
                    <p>
                      Собак-поводырей допустимо перевозить без специальных средств перевозки, в намордниках и при наличии подтверждающих документов.
                    </p>
                  </div>
                }
                label='Можно ли на борт с питомцами?'
                headerBackground={
                  <svg width="759" height="55" viewBox="0 0 759 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 26C0 11.6406 11.6406 0 26 0H425C433.762 0 441.511 4.33386 446.222 10.9748C450.218 16.6086 455.791 22 462.698 22H695.302C702.209 22 707.782 16.6086 711.778 10.9748C716.489 4.33386 724.238 0 733 0C747.359 0 759 11.6406 759 26C759 40.3594 747.359 52 733 52C724.238 52 716.488 47.6656 711.778 41.0239C707.781 35.3893 702.207 29.997 695.3 29.997H462.7C455.793 29.997 450.219 35.3893 446.222 41.0238C441.512 47.6656 433.762 52 425 52H26C11.6406 52 0 40.3594 0 26Z" fill={blue1}/>
                  </svg>
                }
                isWindowWidthTablet={isWindowWidthTablet}
              />
            </div>
          </div>
          <div className={`${classes['questions-columns']} ${classes['questions-columns__one']}`}>
            <div className={classes['questions-column']}>
              <CollapseComponent
                className={classes['collapse-question']}
                iconContainerClassName={classes['collapse-question-iconContainer']}
                hasPseudos={true}
                text={
                  <div style={{display: 'flex', flexDirection: 'column', rowGap: '8px'}}>
                    <p>
                      Как правило, билет приходит на указанный при бронировании e-mail в течение 10-15 минут.
                    </p>
                    <p>
                      Также проверьте папку "спам" на своей почте.
                    </p>
                    <p>
                      Если вы не получили билет, либо допустили ошибку при заполнении адреса почты, свяжитесь с нами <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}>{process.env.REACT_APP_CONTACT_EMAIL}</a> или по номеру телефона <a href={`tel:${process.env.REACT_APP_CONTACT_PHONE}`}>{process.env.REACT_APP_CONTACT_PHONE}</a>
                    </p>
                  </div>
                }
                label='Что делать, если оплаченный билет не пришел на электронную почту?'
                headerBackground={
                  <svg width="1524" height="55" viewBox="0 0 1552 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 26C0 11.6406 11.6406 0 26 0H874C882.762 0 890.511 4.33386 895.222 10.9748C899.218 16.6086 904.791 22 911.698 22H1488.3C1495.21 22 1500.78 16.6086 1504.78 10.9749C1509.49 4.33389 1517.24 0 1526 0C1540.36 0 1552 11.6406 1552 26C1552 40.3594 1540.36 52 1526 52C1517.24 52 1509.49 47.6661 1504.78 41.0251C1500.78 35.3914 1495.21 30 1488.3 30H911.698C904.791 30 899.218 35.3914 895.222 41.0252C890.511 47.6661 882.762 52 874 52H26C11.6406 52 0 40.3594 0 26Z" fill={blue1}/>
                  </svg>
                }
                isWindowWidthTablet={isWindowWidthTablet}
              />
            </div>
            <div className={classes['questions-column']}>
              <CollapseComponent
                className={classes['collapse-question']}
                iconContainerClassName={classes['collapse-question-iconContainer']}
                hasPseudos={true}
                text='Нет, билет распечатывать не обязательно - можно предъявить билет в электронном виде с экрана мобильного телефона.'
                label='Обязательно ли нужно распечатывать билет перед поездкой?'
                headerBackground={
                  <svg width="1524" height="55" viewBox="0 0 1552 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 26C0 11.6406 11.6406 0 26 0H785C793.762 0 801.511 4.33386 806.222 10.9748C810.218 16.6086 815.791 22 822.698 22H1488.3C1495.21 22 1500.78 16.6086 1504.78 10.9749C1509.49 4.33389 1517.24 0 1526 0C1540.36 0 1552 11.6406 1552 26C1552 40.3594 1540.36 52 1526 52C1517.24 52 1509.49 47.6661 1504.78 41.0251C1500.78 35.3914 1495.21 30 1488.3 30H822.698C815.791 30 810.218 35.3914 806.222 41.0252C801.511 47.6661 793.762 52 785 52H26C11.6406 52 0 40.3594 0 26Z" fill={blue1}/>
                  </svg>
                }
                isWindowWidthTablet={isWindowWidthTablet}
              />
            </div>
          </div>
          <div className={`${classes['questions-columns']} ${classes['questions-columns__two']}`}>
            <div className={classes['questions-column']}>
              <CollapseComponent
                className={classes['collapse-question']}
                iconContainerClassName={classes['collapse-question-iconContainer']}
                hasPseudos={true}
                text={
                  <div style={{display: 'flex', flexDirection: 'column', rowGap: '8px'}}>
                    <p>
                      Детям до 2х лет билет оформляется бесплатно <br/> без предоставления места из расчета <br/> 1 взрослый — 1 ребенок. По желанию, для ребенка <br/> вы можете приобрести дополнительный билет <br/> с предоставлением места по детскому тарифу.
                    </p>
                  </div>
                }
                label='Нужно ли покупать билет для ребёнка до 2-х лет?'
                headerBackground={
                  <svg width="759" height="55" viewBox="0 0 759 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 26C0 11.6406 11.6406 0 26 0H610C618.762 0 626.511 4.33386 631.222 10.9748C635.218 16.6086 640.791 22 647.698 22H695.302C702.209 22 707.782 16.6086 711.778 10.9748C716.489 4.33386 724.238 0 733 0C747.359 0 759 11.6406 759 26C759 40.3594 747.359 52 733 52C724.238 52 716.488 47.6656 711.778 41.0239C707.781 35.3893 702.207 29.997 695.3 29.997H647.7C640.793 29.997 635.219 35.3893 631.222 41.0239C626.512 47.6656 618.762 52 610 52H26C11.6406 52 0 40.3594 0 26Z" fill={blue1}/>
                  </svg>
                }
                isWindowWidthTablet={isWindowWidthTablet}
              />
            </div>
            <div className={classes['questions-column']}>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Main