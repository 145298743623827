import React, { useEffect, useRef, useState } from 'react'
import classes from './Booking.module.css'
import Wrapper from '../../components/Wrapper/Wrapper'
import { Divider, Input, Modal, message, notification } from 'antd'
import { Button } from '../../components/Button/Button'
import Image from '../../components/Image/Image'
import bookingImage from '../../images/booking.webp'
import Icons from '../../components/Icons/Icons'
import { getDownloadTicket, getOrder, getOrderByAccessSecret, getReturnTicket } from '../../http/order'
import { BOOKING_ROUTE, beige1, beige4, blue1, windowWidthMobile, windowWidthTablet } from '../../utils/consts'
import moment from 'moment'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import LoadingSpin from '../../components/LoadingSpin/LoadingSpin'
import { getCountries } from '../../http/dictionary'
import { useDispatch, useSelector } from 'react-redux'
import { setCountries } from '../../store/DictionaryStore'
import { setXHRisRunning } from '../../store/SettingsStore'

const Booking = () => {
  const [params] = useSearchParams()

  const [inputOrderNumberPlaceholder, setInputOrderNumberPlaceholder] = useState('Номер')
  const [orderNumber, setOrderNumber] = useState(params.get('orderNumber'))
  const [inputLastNamePlaceholder, setInputLastNamePlaceholder] = useState('Фамилия')
  const [lastName, setLastName] = useState(params.get('lastName'))
  const [accessSecret, setAccessSecret] = useState(null)
  const [orderId, setOrderId] = useState(null)
  const [bookingIsFound, setBookingIsFound] = useState(false)
  const [bookingInfo, setBookingInfo] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [isModalReturnTicketOpen, setIsModalReturnTicketOpen] = useState(false)
  const [currentTicket, setCurrentTicketData] = useState(null)
  const [searchOrderButtonIsLoading, setSearchOrderButtonIsLoading] = useState(false)
  const [returnTicketButtonIsLoading, setReturnTicketButtonIsLoading] = useState(false)
  const [downloadTicketButtonIsLoading, setDownloadTicketButtonIsLoading] = useState(false)
  const countries = useSelector(state => state.dictionary._countries)
  const XHRisRunning = useSelector(state => state.settings._XHRisRunning)
  const dictionaries = useSelector(state => state.dictionary._dictionaries)
  const orderItemStatuses = dictionaries.orderItemStatuses
  const [isWindowWidthTablet, setIsWindowWidthTablet] = useState(window.innerWidth <= windowWidthTablet)
  const [isWindowWidthMobile, setIsWindowWidthMobile] = useState(window.innerWidth <= windowWidthMobile)
  const previewImageRef = useRef()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  let yearDepartureAt
  let yearArrivalAt
  let dayMonthDepartureAt
  let dayMonthArrivalAt
  let timedepartureAt
  let timearrivalAt
  if (bookingInfo) {
    yearDepartureAt = moment(bookingInfo.order.fromRouteStop.departureAt).format('YYYY')
    yearArrivalAt = moment(bookingInfo.order.toRouteStop.arrivalAt).format('YYYY')
    dayMonthDepartureAt = moment(bookingInfo.order.fromRouteStop.departureAt).format('D MMMM')
    dayMonthArrivalAt = moment(bookingInfo.order.toRouteStop.arrivalAt).format('D MMMM')
    timedepartureAt = moment(bookingInfo.order.fromRouteStop.departureAt).format('HH:mm')
    timearrivalAt = moment(bookingInfo.order.toRouteStop.arrivalAt).format('HH:mm')
  }

  const modalReturnTicketHandleOk = () => {
    fetchReturnTicket()
    setIsModalReturnTicketOpen(false)
  }
  const modalReturnTicketHandleCancel = () => {
    setIsModalReturnTicketOpen(false)
  }

  const [messageApi, contextHolder] = notification.useNotification()
  const returnMessage = (type, title, content, className, style, duration) => {
    messageApi.open({
      message: title,
      description: content,
      className: className,
      style: style,
      duration: duration,
      placement: 'top',
      closeIcon:
        <Icons
          name='x'
          size='24px'
          stroke={blue1}
        />,
    })
  }

  const fetchReturnTicket = async (e) => {
    if (XHRisRunning) {
      return false
    }
    else {
      try {
        setReturnTicketButtonIsLoading(true)
        dispatch(setXHRisRunning(true))
        console.log(currentTicket.id, bookingInfo.order.id, lastName, params.get('accessSecret'))
        let {data} = params.get('accessSecret') ? await getReturnTicket(true, currentTicket.id, params.get('accessSecret'), bookingInfo.order.number, lastName) : await getReturnTicket(false, currentTicket.id, params.get('accessSecret'), bookingInfo.order.number, lastName)
        console.log(data)
        if (data.status === 'returned') {
          returnMessage('success', '', `Билет успешно отменён`, '', '', 10)
          params.get('accessSecret') ? fetchOrder(true) : fetchOrder(false)
        }
      } catch (e) {
        console.log(e)
        try {
          e.response.data.data.errors.map((error) => {
            returnMessage('error', e.response.status, `${error.propertyPath == null ? '' : `${error.propertyPath} - `}${error.message}`, '', '', 10)
          })
        } catch (e) {
          returnMessage('error', e.response.status, e.response.data.message, '', '', 10)
        }
      } finally {
        setReturnTicketButtonIsLoading(false)
        dispatch(setXHRisRunning(false))
      }
    }
  }

  const fetchDownloadTicket = async (e, passenger) => {
    if (XHRisRunning) {
      return false
    }
    else {
      e.target.disabled = true
      try {
        setDownloadTicketButtonIsLoading(true)
        dispatch(setXHRisRunning(true))
        let response = params.get('accessSecret') ? await getDownloadTicket(true, passenger.id, params.get('accessSecret'), bookingInfo.order.number || params.get('orderNumber'), lastName) : await getDownloadTicket(false, passenger.id, params.get('accessSecret'), bookingInfo.order.number || params.get('orderNumber'), lastName)
        let filename = ''
        let disposition = response.headers['content-disposition']
        if (disposition && disposition.indexOf('inline') !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          let matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}))
        let alink = document.createElement('a')
        alink.href = url
        alink.download = filename
        alink.click()
      } catch (e) {
        console.log(e)
        returnMessage('error', e.response.status, e.response.data.message, '', '', 10)
      } finally {
        e.target.disabled = false
        setDownloadTicketButtonIsLoading(false)
        dispatch(setXHRisRunning(false))
      }
    }
  }

  const fetchOrder = async (byAccessSecret = false) => {
    if (XHRisRunning) {
      return false
    }
    else {
      setIsLoading(true)
      setSearchOrderButtonIsLoading(true)
      dispatch(setXHRisRunning(true))
      try {
        console.log(orderNumber, lastName)
        console.log(byAccessSecret)
        // let {data} = await getOrder(orderNumber, lastName)
        const uuid = localStorage.getItem('uuid')
        // let {data} = byAccessSecret ? await getOrderByAccessSecret(uuid, params.get('accessSecret')) : await getOrder(orderNumber || params.get('orderNumber'), lastName || params.get('lastName'))
        let {data} = byAccessSecret ? await getOrder(true, params.get('orderId'), params.get('accessSecret'), '', '') : await getOrder(false, '', '', orderNumber || params.get('orderNumber'), lastName || params.get('lastName'))
        console.log(data)
        setBookingIsFound(true)
        setBookingInfo(data)
      } catch (e) {
        console.log(e)
        setBookingIsFound(false)
        try {
          let errors = e.response.data.data
          if (errors) {
            errors.errors.map((error) => {
              returnMessage('error', e.response.status, `${error.propertyPath == null ? '' : `${error.propertyPath} - `}${error.message}`, '', '', 10)
            })
          }
        } catch (e) {
          returnMessage('error', e.response.status, e.response.data.message, '', '', 10)
        }
      } finally {
        setIsLoading(false)
        setSearchOrderButtonIsLoading(false)
        dispatch(setXHRisRunning(false))
      }
    }
  }

  const searchOrder = () => {
    if (XHRisRunning) {
      return false
    }
    else {
      console.log(orderNumber, lastName)
      console.log(orderId, accessSecret)
      if (orderNumber == '' || orderNumber == null || lastName == '' || lastName == null) {
        if (orderNumber == '' || orderNumber == null) {
          setInputOrderNumberPlaceholder('Введите номер')
          returnMessage('error', 'Ошибка', 'Введите номер заказа', '', '', 10)
        }
        if (lastName == '' || lastName == null) {
          setInputLastNamePlaceholder('Введите фамилию')
          returnMessage('error', 'Ошибка', 'Введите фамилию', '', '', 10)
        }
      }
      else {
        console.log('searching')
        if (!accessSecret || isSearching) {
          console.log('searching by default')
          fetchOrder()
        }
        if (accessSecret && !isSearching) {
          console.log('searching by access')
          fetchOrder()
        }
        window.history.pushState({}, '', `${BOOKING_ROUTE}?${createSearchParams({
          orderNumber: orderNumber,
          lastName: lastName,
        })}`)
      }
    }
  }

  const fetchCountries = async () => {
    dispatch(setXHRisRunning(true))
    try {
      let {data} = await getCountries()
      dispatch(setCountries(data))
    } catch (e) {
      console.log(e)
      returnMessage('error', e.response.status, e.response.data.message, '', '', 10)
    } finally {
      dispatch(setXHRisRunning(false))
    }
  }

  const onWindowWidthChange = () => {
    setIsWindowWidthTablet(window.innerWidth <= windowWidthTablet)
    setIsWindowWidthMobile(window.innerWidth <= windowWidthMobile)
  }

  useEffect(() => {
    if (params) {
      setOrderNumber(params.get('orderNumber'))
      setLastName(params.get('lastName'))
      setAccessSecret(params.get('accessSecret'))
      setOrderId(params.get('orderId'))
      if (params.get('orderNumber') || params.get('lastName'))
      searchOrder()
      fetchCountries()
    }
  }, [params])
  
  useEffect(() => {
    if (params.get('accessSecret')) setIsSearching(false)
    if (params.get('accessSecret')) fetchOrder(true)
  }, [])

  useEffect(() => {
    if (!countries.length) {
      fetchCountries()
    }
    window.addEventListener('resize', onWindowWidthChange);
    return () => window.removeEventListener('resize', onWindowWidthChange);
  }, [])

  return (
    <Wrapper>
      {contextHolder}
      <Modal
        className={classes['booking-modal']}
        rootClassName='booking-modal'
        open={isModalReturnTicketOpen}
        onOk={modalReturnTicketHandleOk}
        onCancel={() => setIsModalReturnTicketOpen(false)}
        width={isWindowWidthTablet ? '330px' : '480px'}
        closeIcon={
          <Icons
            name='x'
            size='24px'
            stroke={blue1}
            // className={classes['']} 
          />
        }
        footer={[
          <div className={classes['modal-footer']}>
            <Button
              style={{justifyContent: 'center'}}
              onClick={modalReturnTicketHandleOk}
              key='ok'
              className={classes['modal-button__confirm']}
            >
              Вернуть билет
            </Button>
            <Button
              style={{justifyContent: 'center'}}
              type='outlined'
              onClick={modalReturnTicketHandleCancel}
              key='cancel'
              className={classes['modal-button__cancel']}
            >
              Отмена
            </Button>
          </div>
        ]}
      >
        <div className={classes['modal-content']}>
          <p className={classes['modal-subTitle']}>Вы уверены, <br/> что хотите вернуть билет?</p>
          <p className={classes['modal-text']}>При отмене билета восстановить его будет невозможно. Вы сможете купить новый билет только при его наличии.</p>
        </div>
      </Modal>
      <div className={classes['booking']}>
        <div className={classes['booking-search']}>
          <div className={classes['booking-container']}>
            <div className={classes['booking-column']}>
              <div className={classes['booking-dataDescription']}>Номер брони</div>
              <div className={classes['booking-input-container']}>
                <Input
                  className={classes['booking-input']}
                  value={orderNumber}
                  placeholder={inputOrderNumberPlaceholder}
                  onChange={function(e) {
                    setOrderNumber(e.target.value)
                  }}
                />
                <div className={classes['booking-input-example']}>Например, 20240415-123456</div>
              </div>
            </div>
            <div className={classes['booking-column']}>
              <div className={classes['booking-dataDescription']}>Фамилия</div>
              <div className={classes['booking-input-container']}>
                <Input
                  className={classes['booking-input']}
                  value={lastName}
                  placeholder={inputLastNamePlaceholder}
                  onChange={function(e) {
                    setLastName(e.target.value.toString().toUpperCase())
                  }}
                />
                <div className={classes['booking-input-example']}>Например, Иванов</div>
              </div>
            </div>
          </div>
          <Button
            loading={searchOrderButtonIsLoading}
            onClick={function(e) {
              setIsSearching(true)
              searchOrder()
            }}
            className={classes['booking-button__confirm']}
          >
            Найти
          </Button>
        </div>
        <div className={classes['booking-preview']}>
          <img
            ref={previewImageRef}
            src={bookingImage}
            className={classes['booking-preview-image']}
            alt='Проверьте свою бронь'
          />
          <div className={classes['booking-preview-image-iconContainer']}>
						<Icons
              name='long-arrow'
              size='32px'
              className={classes['booking-preview-image-icon']} 
						/>
					</div>
        </div>
      </div>
      <div className={classes['results']}>
        {bookingIsFound
        ?
          <div className={classes['results']}>
            <div className={classes['results-header']}>
              <div className={classes['results-header-tripData']}>
                <div className={classes['trip-container']}>
                  <div className={classes.trip}>
                    {bookingInfo
                    ?
                      <div className={`${classes['trip-column']} ${classes['trip-route']}`}>
                        <div className={`${classes['trip-column-line']} ${classes['trip-column-line__header']}`}>
                          <div className={classes['trip-column-title-container']}>
                            <div className={classes['trip-column-title']}>
                              {bookingInfo.order.fromRouteStop.stop.name}
                            </div>
                            <svg className={classes['trip-column-title-separator']} width="183" height="64" viewBox="0 0 183 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M84 34C72.726 34 63.8686 43.6601 56.6925 52.3553C50.8232 59.467 41.941 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C41.941 0 50.8232 4.53298 56.6925 11.6447C63.8686 20.3399 72.726 30 84 30C95.274 30 104.131 20.3399 111.308 11.6447C117.177 4.53298 126.059 0 136 0C150.485 0 162.723 9.62455 166.666 22.8276C167.84 26.7577 171.136 30 175.237 30H181C182.105 30 183 30.8954 183 32C183 33.1046 182.105 34 181 34H175.237C171.136 34 167.84 37.2423 166.666 41.1724C162.723 54.3755 150.485 64 136 64C126.059 64 117.177 59.467 111.308 52.3553C104.131 43.6601 95.274 34 84 34Z" fill="#FAF1E6"/>
                            </svg>
                          </div>
                          <div className={classes['trip-column-title']}>
                            {bookingInfo.order.toRouteStop.stop.name}
                          </div>
                        </div>
                        <div className={`${classes['trip-column-line']} ${classes['trip-departureAndArrival']}`}>
                          <div className={classes['trip-column-card']}>
                            <div className={classes['trip-column-card-title']}>Отправление</div>
                            <div className={classes['trip-column-card-column']}>
                              <div className={classes['trip-column-card-column-line']}>
                                {dayMonthDepartureAt}, {yearDepartureAt}
                              </div>
                              <div className={classes['trip-column-card-separator']}></div>
                              <div className={classes['trip-column-card-column-line']}>
                                {timedepartureAt}
                              </div>
                            </div>
                          </div>
                          <div className={classes['trip-column-card']}>
                            <div className={classes['trip-column-card-title']}>Прибытие</div>
                            <div className={classes['trip-column-card-column']}>
                              <div className={classes['trip-column-card-column-line']}>
                                {dayMonthArrivalAt}, {yearArrivalAt}
                              </div>
                              <div className={classes['trip-column-card-separator']}></div>
                              <div className={classes['trip-column-card-column-line']}>
                                {timearrivalAt}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    :
                      <LoadingSpin type="component"/>
                    }
                  </div>
                </div>
              </div>
              <div className={classes['results-header-summary']}>
                <div className={classes['results-header-summary-table']}>
                  {bookingInfo.order.items.map((passenger, index) => 
                    <div className={classes['results-header-summary-table-passengerData']} key={passenger.id}>
                      <div className={classes['results-header-summary-table-passengerData-header']}>
                        <div className={classes['results-header-summary-table-passengerData-header-title']}>Пассажир {index + 1}</div>
                        <div className={classes['results-header-summary-table-passengerData-header-separator']}></div>
                      </div>
                      <div className={classes['results-header-summary-table-passengerData-info']}>
                        <div className={classes['results-header-summary-table-passengerData-info-tariff']}>
                          <div className={classes['results-header-summary-table-passengerData-info-tariff-title']}>
                            {passenger.tariff.name}
                          </div>
                          <div className={classes['results-header-summary-table-passengerData-info-tariff-separator']}></div>
                          <div className={classes['results-header-summary-table-passengerData-info-tariff-price']}>
                            {Number(passenger.price)} ₽
                          </div>
                        </div>
                        <div className={classes['results-header-summary-table-passengerData-info-baggage']}>
                          <div className={classes['results-header-summary-table-passengerData-info-baggage-title']}>
                            Багаж
                          </div>
                          <div className={classes['results-header-summary-table-passengerData-info-baggage-separator']}></div>
                          <div className={classes['results-header-summary-table-passengerData-info-baggage-price']}>
                            {Number(passenger.baggagePrice)} ₽
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={classes['results-header-summary-total']}>
                  <div className={classes['results-header-summary-total-title']}>Общая стоимость</div>
                  <span className={classes['results-header-summary-total-separator']}></span>
                  <div className={classes['results-header-summary-total-price']}>{Number(bookingInfo.order.price)} ₽</div>
                </div>
              </div>
            </div>
            <div className={classes['results-general']}>
              {isWindowWidthMobile
              ?
                <div className={classes['results-general-header']}>
                  <div className={`${classes['results-general-header-title']}`}>Общие данные</div>
                  <div className={classes['results-general-header-separator']}></div>
                </div>
              :
                <Divider orientation="left">
                  Общие данные
                </Divider>
              }
              <div className={classes['results-general-info']}>
                <div className={classes['results-general-info-userData']}>
                  <div className={classes['results-data-item']}>
                    <div className={classes['results-data-item-title']}>Номер брони</div>
                    <div className={classes['results-data-item-content']}>{bookingInfo.order.number}</div>
                  </div>
                  <div className={classes['results-data-item']}>
                    <div className={classes['results-data-item-title']}>Телефон</div>
                    <div className={classes['results-data-item-content']}>{bookingInfo.order.phoneNumber}</div>
                  </div>
                  <div className={classes['results-data-item']}>
                    <div className={classes['results-data-item-title']}>Почта</div>
                    <div className={classes['results-data-item-content']}>{bookingInfo.order.email}</div>
                  </div>
                  <div className={classes['results-data-item']}>
                    <div className={classes['results-data-item-title']}>Фамилия заказчика</div>
                    <div className={classes['results-data-item-content']}>{bookingInfo.order.ownerLastName}</div>
                  </div>
                  <div className={classes['results-data-item']}>
                    <div className={classes['results-data-item-title']}>Имя заказчика</div>
                    <div className={classes['results-data-item-content']}>{bookingInfo.order.ownerFirstName}</div>
                  </div>
                  <div className={classes['results-data-item']}>
                    <div className={classes['results-data-item-title']}>Отчество заказчика</div>
                    <div className={classes['results-data-item-content']}>{bookingInfo.order.ownerMiddleName || '-'}</div>
                  </div>
                </div>
                <div className={classes['results-general-info-controls']}>
                  {bookingInfo.order.status == 'pending_payment' &&
                    <a href={bookingInfo.paymentUrl} style={{textDecoration: 'none'}}>
                      <Button
                        type='outline'
                      >
                        Оплатить заказ
                      </Button>
                    </a>
                  }
                </div>
              </div>
            </div>
            <div className={classes['results-passengers']}>
              {bookingInfo.order.items.map((passenger, index) => 
                <div className={`${classes['results-passengers-passengerInfo']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled']}`}`} key={passenger.id}>
                  <div className={`${classes['results-passengers-passengerInfo-header']}`}>
                    <div className={`${classes['results-passengers-passengerInfo-header-label']} ${classes['results-passengers-passengerInfo-header-passengerNumber']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-header-label']} ${classes['results-passengers-passengerInfo__canceled-header-passengerNumber']}`}`}>Пассажир {index + 1}</div>
                    <div className={`${classes['results-passengers-passengerInfo-header-separator']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-header-separator']}`}`}></div>
                    <div className={`${classes['results-passengers-passengerInfo-header-label']} ${classes['results-passengers-passengerInfo-header-seatNumber']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-header-label']} ${classes['results-passengers-passengerInfo__canceled-header-seatNumber']}`}`}>Место {passenger.seatNumber}</div>
                  </div>
                  <div className={`${classes['results-passengers-passengerInfo-table']}`}>
                    <div className={`${classes['results-passengers-passengerInfo-table-data-item']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item']}`}`} key={passenger.id}>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-title']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-title']}`}`}>Имя</div>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-content']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-content']}`}`}>{passenger.document.firstName.toString().toUpperCase()}</div>
                    </div>
                    <div className={`${classes['results-passengers-passengerInfo-table-data-item']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item']}`}`}>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-title']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-title']}`}`}>Фамилия</div>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-content']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-content']}`}`}>{passenger.document.lastName.toString().toUpperCase()}</div>
                    </div>
                    <div className={`${classes['results-passengers-passengerInfo-table-data-item']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item']}`}`}>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-title']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-title']}`}`}>Отчество</div>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-content']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-content']}`}`}>{passenger.document.middleName || '-'}</div>
                    </div>
                    <div className={`${classes['results-passengers-passengerInfo-table-data-item']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item']}`}`}>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-title']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-title']}`}`}>Пол</div>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-content']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-content']}`}`}>{passenger.document.gender === 'm' ? 'Мужской' : passenger.document.gender === 'f' ? 'Женский' : '-'}</div>
                    </div>
                    <div className={`${classes['results-passengers-passengerInfo-table-data-item']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item']}`}`}>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-title']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-title']}`}`}>Документ</div>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-content']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-content']}`}`}>{passenger.document.documentType === 'passport' ? 'Паспорт' : passenger.document.documentType === 'birthCertificate' ? 'Свидетельство о рождении' : '-' }</div>
                    </div>
                    <div className={`${classes['results-passengers-passengerInfo-table-data-item']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item']}`}`}>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-title']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-title']}`}`}>Номер</div>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-content']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-content']}`}`}>{passenger.document.documentNumber}</div>
                    </div>
                    <div className={`${classes['results-passengers-passengerInfo-table-data-item']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item']}`}`}>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-title']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-title']}`}`}>Гражданство</div>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-content']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-content']}`}`}>{countries.length ? countries.find(country => country.code == passenger.document.countryCode).name : '-'}</div>
                    </div>
                    <div className={`${classes['results-passengers-passengerInfo-table-data-item']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item']}`}`}>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-title']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-title']}`}`}>Дата рождения</div>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-content']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-content']}`}`}>{moment(passenger.document.birthday).format('DD.MM.YYYY')}</div>
                    </div>
                    <div className={`${classes['results-passengers-passengerInfo-table-data-item']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item']}`}`}>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-title']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-title']}`}`}>Багаж</div>
                      <div className={`${classes['results-passengers-passengerInfo-table-data-item-content']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['results-passengers-passengerInfo__canceled-table-data-item-content']}`}`}>{passenger.baggageCount}</div>
                    </div>
                  </div>
                  <div className={classes['results-passengers-passengerInfo-footer']}>
                    {!passenger.parentId &&
                      <Button
                        loading={returnTicketButtonIsLoading}
                        className={`${classes['results-passengers-passengerInfo-footer-button']} ${passenger.status != 'paid' && `${classes['results-passengers-passengerInfo__canceled-footer-button']}`}`}
                        disabled={passenger.status != 'paid'}
                        onClick={() => {
                          setCurrentTicketData(passenger)
                          setIsModalReturnTicketOpen(true)
                        }}
                      >
                        {passenger.status == 'paid' ? 'Вернуть билет' : orderItemStatuses[passenger.status]}
                      </Button>
                    }
                    {passenger.status == 'paid' &&
                      <Button
                        loading={downloadTicketButtonIsLoading}
                        type='outlined'
                        className={classes['results-general-info-controls-downloadTicket']}
                        onClick={(e) => {
                          setCurrentTicketData(passenger)
                          fetchDownloadTicket(e, passenger)
                        }}
                      >
                        <Icons
                          name='download'
                          size='22px'
                          stroke={beige1}
                          className={classes['results-general-info-controls-downloadTicket-icon']}
                        />
                        Скачать билет
                      </Button>
                    }
                  </div>
                </div>
              )}
            </div>
            <div className={classes['results-ticketControls']}>
              {bookingInfo.order.status == 'pending_payment' &&
                <a href={bookingInfo.paymentUrl} style={{textDecoration: 'none'}}>
                  <Button
                    type='outline'
                  >
                    Оплатить заказ
                  </Button>
                </a>
              }
            </div>
          </div>
        :
          isLoading
          ?
            <LoadingSpin type='fullpage'/>
          :
            <div className={classes['results-notFound']}>
            <div className={classes['results-notFound-header']}>
              {!isWindowWidthMobile &&
                <div className={classes['results-notFound-iconContainer']}>
                  <Icons
                    name='warning'
                    size='52px'
                    fill={blue1}
                    className={classes['results-notFound-icon']}
                  />
                </div>
              }
              <div className={classes['results-notFound-title']}>
              {!isWindowWidthMobile &&
                <svg style={{position: 'absolute', top: '0', left: '0'}} fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M 49.8719 116 C 81.8221 116 107.723 90.0325 107.723 58 C 107.723 25.9675 81.8221 0 49.8719 0 H 107.723 C 107.723 0 107.723 17.9086 107.723 40 V 76 C 107.723 98.0914 107.723 116 107.723 116 H 49.8719 Z M 14.8042 11.8669 C 9.8546 15.6545 0 12.3495 0 6.1169 V 6.1169 C 0 2.7386 2.7386 0 6.1169 0 H 49.8719 C 36.6876 0 24.5334 4.4218 14.8042 11.8669 Z M 0 109.883 C 0 103.65 9.8546 100.345 14.8042 104.133 C 24.5334 111.578 36.6876 116 49.8719 116 H 6.1169 C 2.7386 116 0 113.261 0 109.883 V 109.883 Z" fill={blue1}/>
                </svg>
              }
                Бронирование не найдено
              </div>
            </div>
            <div className={classes['results-notFound-informationMessage']}>
              Ваше бронирование не найдено. Проверьте данные и попробуйте ещё раз, или обратитесь в службу поддержки.
            </div>
            <div className={classes['results-notFound-support']}>
              <div className={classes['results-notFound-support-source']}>
                <Icons
                  name='phone'
                  size='58px'
                  stroke={blue1}
                  className={classes['results-notFound-support-source-icon']}
                />
                <a href={`tel:${process.env.REACT_APP_CONTACT_PHONE}`} className={classes['results-notFound-support-source-text']}>
                  {process.env.REACT_APP_CONTACT_PHONE}
                </a>
              </div>
              <div className={classes['results-notFound-support-source']}>
                <Icons
                  name='mail'
                  size='58px'
                  stroke={blue1}
                  className={classes['results-notFound-support-source-icon']}
                />
                <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} className={classes['results-notFound-support-source-text']}>
                  {process.env.REACT_APP_CONTACT_EMAIL}
                </a>
              </div>
            </div>
          </div>
        }
      </div>
    </Wrapper>
  )
}

export default Booking