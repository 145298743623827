import React from 'react'
import { useInView } from 'react-intersection-observer'
import { gray } from '../../utils/consts'

const Image = ({...props}) => {
  const {ref, inView} = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  return (
    <div style={{height: !inView && '500px'}} ref={ref}>
      {inView
      ?
        <img
          {...props}
        />
      :
        <div className={props.className} style={{background: gray}}></div>
      }
    </div>
  )
}

export default Image