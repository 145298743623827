import { $host } from './index'

export const getStops = async () => {
    const {data} = await $host.get('v1/search/stops');
    return data;
}

export const getTrips = async (dateTime, fromStopId, toStopId) => {
    const {data} = await $host.post('v1/search/trips', {dateTime, fromStopId, toStopId});
    return data;
}