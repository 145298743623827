import { $host } from './index'

export const getOrder = async (byAccess, orderId, accessSecret, orderNumber, lastName) => {
  if (byAccess) {
    console.log('byAccess')
    const {data} = await $host.post('v1/orders/search', {orderId, accessSecret});
    return data;
  } 
  else {
    const {data} = await $host.post('v1/orders/search', {orderNumber, lastName});
    return data;
  }
}

export const getOrderByAccessSecret = async (uuid, accessSecret) => {
  const {data} = await $host.get(`v1/checkout/orders/${uuid}?accessSecret=${accessSecret}`);
  return data;
}

export const getDownloadTicket = async (byAccess, orderItemId, accessSecret, orderNumber, lastName) => {
  if (byAccess) {
    const response = await $host.get(`v1/orders/download-ticket?orderItemId=${orderItemId}&accessSecret=${accessSecret}`, {responseType: 'blob', ContentType: 'application/pdf'});
    return response;
  } 
  else {
    const response = await $host.get(`v1/orders/download-ticket?orderItemId=${orderItemId}&orderNumber=${orderNumber}&lastName=${lastName}`, {responseType: 'blob', ContentType: 'application/pdf'});
    return response;
  }
}

export const getReturnTicket = async (byAccess, orderItemId, accessSecret, orderNumber, lastName) => {
  if (byAccess) {
    const {data} = await $host.post('v1/orders/return-ticket', {orderItemId, accessSecret});
    return data;
  } 
  else {
    const {data} = await $host.post('v1/orders/return-ticket', {orderItemId, orderNumber, lastName});
    return data;
  }
}