// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../utils/fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduleDescription_route__dOby\\+ {
  width: 100%;
  max-width: 628px;
  background: transparent;
}
.ScheduleDescription_route-subtitle__S41uV {
  margin-top: 88px;
  width: 100%;
  max-width: 432px;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.ScheduleDescription_route-subtitle-bg__dJr\\+K {
  position: absolute;
  margin: 0;
}
.ScheduleDescription_route-subtitle-title__EeARQ {
  z-index: 1;
  font-size: 23px;
  line-height: 1;
  font-family: "Cygre Bold";
  color: #E6EAFA;
  padding: 15px 40px;
}
@media (max-width: 500px) {
  .ScheduleDescription_route-subtitle-title__EeARQ {
    font-size: 17px;
    line-height: 1;
    font-family: "Cygre Bold";
  }
}
@-moz-document url-prefix() {
  .ScheduleDescription_route-subtitle-title__EeARQ {
    padding: 19px 40px 11px;
  }
}
.ScheduleDescription_route-subtitle-title__secondary__JOaRL {
  color: #FAF1E6;
}
@media (max-width: 834px) {
  .ScheduleDescription_route-subtitle-title__secondary__JOaRL {
    background: #9AB0E5;
    padding: 15px 24px;
    border-radius: 28px;
  }
}
.ScheduleDescription_route-description__7dIwL {
  display: flex;
  flex-direction: column;
  color: #274898;
  margin-top: 24px;
}

@media (max-width: 760px) {
  .ScheduleDescription_route-subtitle__S41uV {
    margin-top: 30px;
    max-width: 364px;
  }
  .ScheduleDescription_route-subtitle-bg__dJr\\+K {
    margin: 0 0 0 -37px;
  }
  .ScheduleDescription_route-subtitle-title__EeARQ {
    padding: 17px 40px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ScheduleDescription/ScheduleDescription.module.css"],"names":[],"mappings":"AACA;EACE,WAAW;EACX,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,kBAAkB;EAClB,SAAS;AACX;AACA;EACE,UAAU;EACV,eAAe;EACf,cAAc;EACd,yBAAyB;EACzB,cAAc;EACd,kBAAkB;AACpB;AACA;EACE;IACE,eAAe;IACf,cAAc;IACd,yBAAyB;EAC3B;AACF;AACA;EACE;IACE,uBAAuB;EACzB;AACF;AACA;EACE,cAAc;AAChB;AACA;EACE;IACE,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;EACrB;AACF;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;IAChB,gBAAgB;EAClB;EACA;IACE,mBAAmB;EACrB;EACA;IACE,kBAAkB;EACpB;AACF","sourcesContent":["@import '../../utils/fonts.css';\n.route {\n  width: 100%;\n  max-width: 628px;\n  background: transparent;\n}\n.route-subtitle {\n  margin-top: 88px;\n  width: 100%;\n  max-width: 432px;\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n}\n.route-subtitle-bg {\n  position: absolute;\n  margin: 0;\n}\n.route-subtitle-title {\n  z-index: 1;\n  font-size: 23px;\n  line-height: 1;\n  font-family: \"Cygre Bold\";\n  color: #E6EAFA;\n  padding: 15px 40px;\n}\n@media (max-width: 500px) {\n  .route-subtitle-title {\n    font-size: 17px;\n    line-height: 1;\n    font-family: \"Cygre Bold\";\n  }\n}\n@-moz-document url-prefix() {\n  .route-subtitle-title {\n    padding: 19px 40px 11px;\n  }\n}\n.route-subtitle-title__secondary {\n  color: #FAF1E6;\n}\n@media (max-width: 834px) {\n  .route-subtitle-title__secondary {\n    background: #9AB0E5;\n    padding: 15px 24px;\n    border-radius: 28px;\n  }\n}\n.route-description {\n  display: flex;\n  flex-direction: column;\n  color: #274898;\n  margin-top: 24px;\n}\n\n@media (max-width: 760px) {\n  .route-subtitle {\n    margin-top: 30px;\n    max-width: 364px;\n  }\n  .route-subtitle-bg {\n    margin: 0 0 0 -37px;\n  }\n  .route-subtitle-title {\n    padding: 17px 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"route": `ScheduleDescription_route__dOby+`,
	"route-subtitle": `ScheduleDescription_route-subtitle__S41uV`,
	"route-subtitle-bg": `ScheduleDescription_route-subtitle-bg__dJr+K`,
	"route-subtitle-title": `ScheduleDescription_route-subtitle-title__EeARQ`,
	"route-subtitle-title__secondary": `ScheduleDescription_route-subtitle-title__secondary__JOaRL`,
	"route-description": `ScheduleDescription_route-description__7dIwL`
};
export default ___CSS_LOADER_EXPORT___;
