import React from 'react'
import shipSchemeSVG from '../../assets/ship-scheme.svg'
import { useInView } from 'react-intersection-observer'
import { gray } from '../../utils/consts'

const ShipScheme = ({width, height, viewBox}) => {
  const {ref, inView} = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  return (
    <div ref={ref} style={{width: !inView && width, height: !inView && height}}>
      {inView
      ?
        <svg viewBox={viewBox}>
          <use xlinkHref={`${shipSchemeSVG}#scheme`} width={width} height={height}/>
        </svg>
      :
        <div style={{width: width, height: height, background: gray}}></div>
      }
    </div>
  )
}

export default ShipScheme