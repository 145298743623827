import React, { useEffect, useState } from 'react'
import classes from './Footer.module.css'
import Wrapper from '../Wrapper/Wrapper'
import Logo from '../Logo/Logo'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import Icons from '../Icons/Icons'
import { ABOUT_ROUTE, INFO_ROUTE, MAIN_ROUTE, PRIVACYPOLICY_ROUTE, blue1, windowWidthMobile, windowWidthTablet } from '../../utils/consts'
import H1 from '../H1/H1'

const Footer = () => {
  const [isWindowWidthTablet, setIsWindowWidthTablet] = useState(window.innerWidth <= windowWidthTablet)
  const [isWindowWidthMobile, setIsWindowWidthMobile] = useState(window.innerWidth <= windowWidthMobile)

  const onWindowWidthChange = () => {
    setIsWindowWidthTablet(window.innerWidth <= windowWidthTablet)
    setIsWindowWidthMobile(window.innerWidth <= windowWidthMobile)
  }
  useEffect(() => {
    window.addEventListener('resize', onWindowWidthChange);
    return () => window.removeEventListener('resize', onWindowWidthChange);
  }, [])

  console.log(isWindowWidthMobile)
  return (
    <Wrapper>
      <footer className={classes['footer']}>
        {isWindowWidthTablet && !isWindowWidthMobile && 
          <H1 className={classes['footer-title']}>Обратиться в поддержку</H1>
        }
        <div className={classes['footer-table']}>
          <div className={classes['footer-table-links']}>
            <Link to={MAIN_ROUTE} className={classes['footer-table-links-link']}>
              Купить билет
            </Link>
            <HashLink to={`${MAIN_ROUTE}\#schedule`} className={classes['footer-table-links-link']} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              Описание направлений
            </HashLink>
            <Link to={ABOUT_ROUTE} className={classes['footer-table-links-link']}>
              О компании
            </Link>
            <HashLink to={`${MAIN_ROUTE}\#questions`} className={classes['footer-table-links-link']} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
              Часто завадаемые вопросы
            </HashLink>
            <Link to={MAIN_ROUTE} className={classes['footer-table-links-link']}>
              Правовая информация
            </Link>
            <Link to={INFO_ROUTE} className={classes['footer-table-links-link']}>
              Информация для пассажиров
            </Link>
          </div>
          <div className={classes['footer-table-support']}>
            <div className={classes['footer-table-support-source']}>
              <Icons
                stroke={blue1}
                name='phone'
                size='44px'
                className={classes['footer-table-support-source-icon']}
              />
              <a href={`tel:${process.env.REACT_APP_CONTACT_PHONE}`} className={classes['footer-table-support-source-text']}>
                {process.env.REACT_APP_CONTACT_PHONE}
              </a>
            </div>
            <div className={classes['footer-table-support-source']}>
              <Icons
                stroke={blue1}
                name='mail'
                size='44px'
                className={classes['footer-table-support-source-icon']}
              />
              <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} className={classes['footer-table-support-source-text']}>
                {process.env.REACT_APP_CONTACT_EMAIL}
              </a>
            </div>
          </div>
          <div className={classes['footer-table-license']}>
            <Link to={PRIVACYPOLICY_ROUTE} className={classes['footer-table-license-link']}>
              Политика конфиденциальности
            </Link>
            <Link to={MAIN_ROUTE} className={classes['footer-table-license-link']}>
              Лицензия
            </Link>
          </div>
          <div className={classes['footer-table-socials']}>
            <svg width="96" height="37" viewBox="0 0 96 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M48 22C40.7206 22 34.7386 27.8312 28.9704 32.2717C25.9334 34.6097 22.1291 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C22.1291 0 25.9334 1.39029 28.9704 3.72829C34.7386 8.16876 40.7206 14 48 14C55.2794 14 61.2614 8.16877 67.0296 3.72829C70.0666 1.39029 73.8709 0 78 0C87.9411 0 96 8.05887 96 18C96 27.9411 87.9411 36 78 36C73.8709 36 70.0666 34.6097 67.0296 32.2717C61.2614 27.8312 55.2794 22 48 22Z" fill="#274898"/>
            </svg>
            <div className={classes["footer-table-socials-container"]}>
              <a className={classes["footer-table-socials-link"]} href={process.env.REACT_APP_CONTACT_TELEGRAM} target="_blank">
                <Icons
                  name='telegram'
                  size='24px'
                  className={classes.icon}
                />
              </a>
              <a className={classes["footer-table-socials-link"]} href={process.env.REACT_APP_CONTACT_VK} target="_blank">
                <Icons
                  name='vk'
                  size='24px'
                  className={classes.icon}
                />
              </a>
            </div>
          </div>
          <div className={classes['footer-table-logo']}>
            <Logo className={classes.logo} color='blue' width={isWindowWidthMobile ? '97px' : '270px'} height={isWindowWidthMobile ? '36px' : '96px'}/>
          </div>
        </div>
      </footer>
    </Wrapper>
  )
}

export default Footer