import React, { useEffect, useState } from 'react';
import classes from './NavBar.module.css';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { AUTH_ROUTE, MAIN_ROUTE, ABOUT_ROUTE, ACCOUNT_ROUTE, INFO_ROUTE, windowWidthTablet, beige4, PRIVACYPOLICY_ROUTE, windowWidthMobile } from '../../utils/consts';
import Icons from '../Icons/Icons';
import { Button } from '../Button/Button';
import H1 from '../H1/H1';
import { Link } from 'react-router-dom';
import Logo from '../Logo/Logo';

const NavBar = () => {
  const [isModalMenuOpen, setIsModalMenuOpen] = useState(false)
  const [isWindowWidthTablet, setIsWindowWidthTablet] = useState(window.innerWidth <= windowWidthTablet)
  const [isWindowWidthMobile, setIsWindowWidthMobile] = useState(window.innerWidth <= windowWidthMobile)

  const onWindowWidthChange = () => {
    setIsWindowWidthTablet(window.innerWidth <= windowWidthTablet)
    setIsWindowWidthMobile(window.innerWidth <= windowWidthMobile)
  }
  useEffect(() => {
    window.addEventListener('resize', onWindowWidthChange);
    return () => window.removeEventListener('resize', onWindowWidthChange);
  }, [])

  return (
    <nav className={classes.bar}>
      {isModalMenuOpen &&
        <div
          className={classes['modalMenu']}
          width='100vw'
          height='100vh'
        >
          <div>
            <div className={classes['modalMenu-header']}>
              <div className={classes.menu}>
                <div className={classes.links}>
                  <Button
                    className={classes['modalMenu-menu-iconContainer']}
                    onClick={() => setIsModalMenuOpen(false)}
                  >
                    {/* <div className={classes['menu-iconContainer']}> */}
                      <Icons
                        name='x-blue'
                        size='24px'
                        className={classes['modalMenu-menu-icon']}
                      />
                    {/* </div> */}
                  </Button>
                </div>
              </div>
              <div className={classes.menu}>
                <div className={classes.links}>
                  {!isWindowWidthMobile &&
                    <a href={`tel:${process.env.REACT_APP_CONTACT_PHONE}`} className={classes.phoneNumber}>{process.env.REACT_APP_CONTACT_PHONE}</a>
                  }
                  <div className={classes.socials}>
                    <svg width="96" height="36" viewBox="0 0 97 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M48 22C40.7206 22 34.7386 27.8312 28.9704 32.2717C25.9334 34.6097 22.1291 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C22.1291 0 25.9334 1.39029 28.9704 3.72829C34.7386 8.16876 40.7206 14 48 14C55.2794 14 61.2614 8.16877 67.0296 3.72829C70.0666 1.39029 73.8709 0 78 0C87.9411 0 96 8.05887 96 18C96 27.9411 87.9411 36 78 36C73.8709 36 70.0666 34.6097 67.0296 32.2717C61.2614 27.8312 55.2794 22 48 22Z" fill={beige4}/>
                    </svg>
                    <div className={classes["socials-container"]}>
                      <a className={classes["socials-link"]} href={process.env.REACT_APP_CONTACT_TELEGRAM} target="_blank">
                        <Icons
                          name='telegram-blue'
                          size='24px'
                          className={classes.icon}
                        />
                      </a>
                      <a className={classes["socials-link"]} href={process.env.REACT_APP_CONTACT_VK} target="_blank">
                        <Icons
                          name='vk-blue'
                          size='24px'
                          className={classes.icon}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes['modalMenu-body']}>
              <div className={classes['modalMenu-body-links']}>
              <NavLink className={classes['modalMenu-body-links-link']} to={MAIN_ROUTE} onClick={() => setIsModalMenuOpen(false)}>
                <div className={classes['modalMenu-body-links-link-text']}>Главная</div>
                <Icons
                  name='arrow-left'
                  stroke={beige4}
                  size='24px'
                  className={classes['modalMenu-body-links-link-icon']}  
                />
              </NavLink>
              <HashLink className={classes['modalMenu-body-links-link']} to={`${MAIN_ROUTE}\#schedule`} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })} onClick={() => setIsModalMenuOpen(false)}>
                <div className={classes['modalMenu-body-links-link-text']}>Расписание</div>
                <Icons
                  name='arrow-left'
                  stroke={beige4}
                  size='24px'
                  className={classes['modalMenu-body-links-link-icon']}  
                />
              </HashLink>
              <NavLink className={classes['modalMenu-body-links-link']} to={ABOUT_ROUTE} onClick={() => setIsModalMenuOpen(false)}>
                <div className={classes['modalMenu-body-links-link-text']}>О нас</div>
                <Icons
                  name='arrow-left'
                  stroke={beige4}
                  size='24px'
                  className={classes['modalMenu-body-links-link-icon']}  
                />
              </NavLink>
              <NavLink className={classes['modalMenu-body-links-link']} to={INFO_ROUTE} onClick={() => setIsModalMenuOpen(false)}>
                <div className={classes['modalMenu-body-links-link-text']}>Информация</div>
                <Icons
                  name='arrow-left'
                  stroke={beige4}
                  size='24px'
                  className={classes['modalMenu-body-links-link-icon']}  
                />
              </NavLink>
              </div>
            </div>
          </div>
          <div className={classes['modalMenu-footer']}>
            {!isWindowWidthMobile &&
              <H1>Обратиться в поддержку</H1>
            }
            <div className={classes['modalMenu-footer-row']}>
              <div className={classes['modalMenu-footer-support']}>
                <a href={`tel:${process.env.REACT_APP_CONTACT_PHONE}`} className={classes['modalMenu-footer-support-source']}>
                  <Icons
                    stroke={beige4}
                    name='phone'
                    size='44px'
                    className={classes['modalMenu-footer-support-source-icon']}
                  />
                  {process.env.REACT_APP_CONTACT_PHONE}
                </a>
                <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} className={classes['modalMenu-footer-support-source']}>
                  <Icons
                    stroke={beige4}
                    name='mail'
                    size='44px'
                    className={classes['modalMenu-footer-support-source-icon']}
                  />
                  {process.env.REACT_APP_CONTACT_EMAIL}
                </a>
              </div>
            </div>
            <div className={classes['modalMenu-footer-row']}>
              <div className={classes['modalMenu-footer-license']}>
                <Link to={PRIVACYPOLICY_ROUTE} className={classes['modalMenu-footer-license-link']}>
                  Политика конфиденциальности
                </Link>
                <Link to={MAIN_ROUTE} className={classes['modalMenu-footer-license-link']}>
                  Лицензия
                </Link>
              </div>
              <div className={classes['modalMenu-footer-logo']}>
                <Logo className={classes.logo} color='yellow' width='238px' height='84px'/>
              </div>
            </div>
          </div>
        </div>
      }
      <div className={classes.content}>
        <div className={classes.menu}>
          {isWindowWidthTablet || isWindowWidthMobile ?
          <div className={classes.links}>
            {isWindowWidthMobile && <Logo className={classes['logo']} color='blue' width='97px' height='36px' viewBox="0 0 96 36"/>}
            <Button
              className={classes['menu-iconContainer']}
              onClick={() => setIsModalMenuOpen(true)}
            >
              {/* <div className={classes['menu-iconContainer']}> */}
                <Icons
                  name='menu'
                  size='24px'
                  className={classes['menu-icon']}
                />
              {/* </div> */}
            </Button>
          </div>
          :
          <div className={classes.links}>
            <NavLink className={classes.link} to={MAIN_ROUTE}>
              Главная
            </NavLink>
            <HashLink className={classes.link} to={`${MAIN_ROUTE}\#schedule`} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Расписание</HashLink>
            <NavLink className={classes.link} to={ABOUT_ROUTE}>
              О нас
            </NavLink>
            <NavLink className={classes.link} to={INFO_ROUTE}>
              Информация
            </NavLink>
          </div>
          }
        </div>
        <div className={classes.menu}>
          <div className={classes.links}>
            {!isWindowWidthMobile &&
              <a href={`tel:${process.env.REACT_APP_CONTACT_PHONE}`} className={classes.phoneNumber}>{process.env.REACT_APP_CONTACT_PHONE}</a>
            }
            <div className={classes.socials}>
              <svg width="96" height="36" viewBox="0 0 96 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M48 22C40.7206 22 34.7386 27.8312 28.9704 32.2717C25.9334 34.6097 22.1291 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C22.1291 0 25.9334 1.39029 28.9704 3.72829C34.7386 8.16876 40.7206 14 48 14C55.2794 14 61.2614 8.16877 67.0296 3.72829C70.0666 1.39029 73.8709 0 78 0C87.9411 0 96 8.05887 96 18C96 27.9411 87.9411 36 78 36C73.8709 36 70.0666 34.6097 67.0296 32.2717C61.2614 27.8312 55.2794 22 48 22Z" fill="#274898"/>
              </svg>
              <div className={classes["socials-container"]}>
                <a className={classes["socials-link"]} href={process.env.REACT_APP_CONTACT_TELEGRAM} target="_blank">
                  <Icons
                    name='telegram'
                    size='24px'
                    className={classes.icon}
                  />
                </a>
                <a className={classes["socials-link"]} href={process.env.REACT_APP_CONTACT_VK} target="_blank">
                  <Icons
                    name='vk'
                    size='24px'
                    className={classes.icon}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default NavBar;