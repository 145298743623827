import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
 
const TripsStore = createSlice({
  name: "trips",
  initialState: {
    _fromStopList: [],
    _toStopList: [],
    _selectedFromStop: null,
    _selectedToStop: null,
    _selectedDate: dayjs(),
    _tripsList: [],
  },
  reducers: {
    setFromStopList(state, action) {
        state._fromStopList = action.payload;
    },
    setToStopList(state, action) {
        state._toStopList = action.payload;
    },
    setSelectedFromStop(state, action) {
        state._selectedFromStop = action.payload;
    },
    setSelectedToStop(state, action) {
        state._selectedToStop = action.payload;
    },
    setSelectedDate(state, action) {
        state._selectedDate = action.payload;
    },
    setTripsList(state, action) {
        state._tripsList = action.payload;
    },
  }
})

export const {setFromStopList, setToStopList, setSelectedFromStop, setSelectedToStop, setSelectedDate, setTripsList} = TripsStore.actions;

export default TripsStore.reducer;