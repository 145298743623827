// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../utils/fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Schedule_schedule__K5\\+qd {
  width: 100%;
  max-width: 628px;
  background: transparent;
}
.Schedule_schedule-subtitle__kF1s- {
  margin-top: 88px;
  width: 100%;
  max-width: 432px;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.Schedule_schedule-subtitle-bg__tumfm {
  position: absolute;
  margin: 0;
}
.Schedule_schedule-subtitle-title__Inw-z {
  z-index: 1;
  font-size: 23px;
  line-height: 1;
  font-family: "Cygre Bold";
  color: #E6EAFA;
  padding: 15px 40px;
}
@media (max-width: 500px) {
  .Schedule_schedule-subtitle-title__Inw-z {
    font-size: 17px;
    line-height: 1;
    font-family: "Cygre Bold";
  }
}
.Schedule_schedule-subtitle-title__secondary__qXgPj {
  color: #274898;
}
.Schedule_schedule-description__qm8EB {
  display: flex;
  flex-direction: column;
  color: #274898;
  margin-top: 24px;
}

@media (max-width: 760px) {
  .Schedule_schedule__K5\\+qd {
    max-width: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Schedule/Schedule.module.css"],"names":[],"mappings":"AACA;EACE,WAAW;EACX,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,kBAAkB;EAClB,SAAS;AACX;AACA;EACE,UAAU;EACV,eAAe;EACf,cAAc;EACd,yBAAyB;EACzB,cAAc;EACd,kBAAkB;AACpB;AACA;EACE;IACE,eAAe;IACf,cAAc;IACd,yBAAyB;EAC3B;AACF;AACA;EACE,cAAc;AAChB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["@import '../../utils/fonts.css';\n.schedule {\n  width: 100%;\n  max-width: 628px;\n  background: transparent;\n}\n.schedule-subtitle {\n  margin-top: 88px;\n  width: 100%;\n  max-width: 432px;\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n}\n.schedule-subtitle-bg {\n  position: absolute;\n  margin: 0;\n}\n.schedule-subtitle-title {\n  z-index: 1;\n  font-size: 23px;\n  line-height: 1;\n  font-family: \"Cygre Bold\";\n  color: #E6EAFA;\n  padding: 15px 40px;\n}\n@media (max-width: 500px) {\n  .schedule-subtitle-title {\n    font-size: 17px;\n    line-height: 1;\n    font-family: \"Cygre Bold\";\n  }\n}\n.schedule-subtitle-title__secondary {\n  color: #274898;\n}\n.schedule-description {\n  display: flex;\n  flex-direction: column;\n  color: #274898;\n  margin-top: 24px;\n}\n\n@media (max-width: 760px) {\n  .schedule {\n    max-width: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"schedule": `Schedule_schedule__K5+qd`,
	"schedule-subtitle": `Schedule_schedule-subtitle__kF1s-`,
	"schedule-subtitle-bg": `Schedule_schedule-subtitle-bg__tumfm`,
	"schedule-subtitle-title": `Schedule_schedule-subtitle-title__Inw-z`,
	"schedule-subtitle-title__secondary": `Schedule_schedule-subtitle-title__secondary__qXgPj`,
	"schedule-description": `Schedule_schedule-description__qm8EB`
};
export default ___CSS_LOADER_EXPORT___;
