import { createSlice } from '@reduxjs/toolkit';
 
const UserStore = createSlice({
  name: "user",
  initialState: {
    _user: {
      type: ['ROLE_USER']
    },
  },
  reducers: {
    setUserType(state, action) {
        state._user.type = action.payload;
        console.log(state._user)
    },
  }
})

export const {setUserType} = UserStore.actions;

export default UserStore.reducer;