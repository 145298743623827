import { configureStore } from '@reduxjs/toolkit'
import TripsStore from './TripsStore'
import TicketStore from './TicketStore'
import UserStore from './UserStore'
import DictionaryStore from './DictionaryStore'
import SettingsStore from './SettingsStore'

export default configureStore({
    reducer: {
        tickets: TicketStore,
        trips: TripsStore,
        user: UserStore,
        dictionary: DictionaryStore,
        settings: SettingsStore,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoreActions: 'setSelectedDate',
            ignoreState: 'trips._selectedDate',
        }
    })
})