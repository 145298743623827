import { createSlice } from '@reduxjs/toolkit';
 
const SettingsStore = createSlice({
    name: "tickets",
    initialState: {
      _settingsList: [],
      _orderCreateEnabled: true,
      _informationMessageText: '',
      _XHRisRunning: false,
    },
    reducers: {
      setSettingsList(state, action) {
        state._settingsList = action.payload;
      },
      setSettingOrderCreateEnabled(state, action) {
        state._orderCreateEnabled = action.payload;
      },
      setSettingInformationMessageText(state, action) {
        state._informationMessageText = action.payload;
      },
      setXHRisRunning(state, action) {
        state._XHRisRunning = action.payload;
      }
    }
})

export const { setSettingsList, setSettingOrderCreateEnabled, setSettingInformationMessageText, setXHRisRunning } = SettingsStore.actions;

export default SettingsStore.reducer;