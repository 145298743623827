import { $host } from './index'

export const getCountries = async () => {
  const {data} = await $host.get('v1/dictionaries/countries');
  return data;
}

export const getDictionaries = async () => {
  const {data} = await $host.get('v1/dictionaries/all');
  return data;
}