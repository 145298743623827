import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Trip from '../../components/Trip/Trip'
import Wrapper from '../../components/Wrapper/Wrapper'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { getStops, getTrips } from '../../http/trip'
import { setFromStopList, setSelectedDate, setSelectedFromStop, setSelectedToStop, setToStopList, setTripsList } from '../../store/TripsStore'
import dayjs from 'dayjs'
import classes from './Trips.module.css'
import { DatePicker, Input, Select, message, notification } from 'antd'
import moment from 'moment'
import Icons from '../../components/Icons/Icons'
import { TRIPS_ROUTE, blue1, windowWidthMobile, windowWidthTablet } from '../../utils/consts'
import { Button } from '../../components/Button/Button'
import tripBanner from '../../images/compressed/trip.webp';
import LoadingSpin from '../../components/LoadingSpin/LoadingSpin'
import { setXHRisRunning } from '../../store/SettingsStore';

const Tickets = () => {
  const tripsList = useSelector(state => state.trips._tripsList)
  const selectedFromStop = useSelector(state => state.trips._selectedFromStop)
  const selectedToStop = useSelector(state => state.trips._selectedToStop)
  const selectedDate = useSelector(state => state.trips._selectedDate)
  const fromStopList = useSelector(state => state.trips._fromStopList)
  const toStopList = useSelector(state => state.trips._toStopList)
  const XHRisRunning = useSelector(state => state.settings._XHRisRunning)
  const isLoading = useRef(true)
  const [searchTripsButtonIsLoading, setSearchTripsButtonIsLoading] = useState(false)
  const [isWindowWidthTablet, setIsWindowWidthTablet] = useState(window.innerWidth <= windowWidthTablet)
  const [isWindowWidthMobile, setIsWindowWidthMobile] = useState(window.innerWidth <= windowWidthMobile)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  
  let date = params.get('date')
  let fromStop = params.get('fromStop')
  let toStop = params.get('toStop')

  const [messageApi, contextHolder] = notification.useNotification()
  const returnMessage = (type, title, content, className, style, duration) => {
    messageApi.open({
      // type: type,
      message: title,
      description: content,
      className: className,
      style: style,
      duration: duration,
      placement: 'top',
      closeIcon:
        <Icons
          name='x'
          size='24px'
          stroke={blue1}
        />,
    })
  }

  const fetchStops = async () => {
    try {
      let {data} = await getStops()
      dispatch(setFromStopList(data))
      dispatch(setToStopList(data))
      console.log(data)
    } catch (e) {
      console.log(e)
      returnMessage('error', e.response.status, e.response.data.message, '', '', 10)
    }
  }

  const fetchTrips = async (searchDate = null, searchFromStop = null, searchToStop = null) => {
    if (XHRisRunning) {
      return false
    }
    else {
      dispatch(setXHRisRunning(true))
      setSearchTripsButtonIsLoading(true)
      try {
        isLoading.current = true
        let tripDate = searchDate || selectedDate.format()
        let tripFromStop = searchFromStop || selectedFromStop || fromStop
        let tripToStop = searchToStop || selectedToStop || toStop
        let {data} = await getTrips(tripDate, tripFromStop, tripToStop)
        dispatch(setTripsList(data))
        navigate({
          pathname: TRIPS_ROUTE,
          search: `?${createSearchParams({
            date: searchDate || selectedDate.format() || date,
            fromStop: selectedFromStop || fromStop,
            toStop: selectedToStop || toStop,
          })}`
        })
      } catch (e) {
        console.log(e)
        returnMessage('error', e.response.status, e.response.data.message, '', '', 10)
        isLoading.current = false
      } finally {
        isLoading.current = false
        dispatch(setXHRisRunning(false))
        setSearchTripsButtonIsLoading(false)
      }
    }
  }

  const switchStops = async () => {
    let fromStopTemp = selectedFromStop
    dispatch(setSelectedFromStop(selectedToStop))
    dispatch(setSelectedToStop(fromStopTemp))
  }

  const disabledDate = (current) => {
    return current < (dayjs().add(-1, 'day').endOf('day')) 
  }

  const onWindowWidthChange = () => {
    setIsWindowWidthTablet(window.innerWidth <= windowWidthTablet)
    setIsWindowWidthMobile(window.innerWidth <= windowWidthMobile)
  }

  useEffect(() => {
    if (!selectedFromStop) dispatch(setSelectedFromStop(fromStop))
    if (!selectedToStop) dispatch(setSelectedToStop(toStop))
    if (selectedDate.format() != date && date !== null) dispatch(setSelectedDate(dayjs(date)))
    if (fromStopList == false) fetchStops()
    fetchTrips(date)
    window.addEventListener('resize', onWindowWidthChange);
    return () => window.removeEventListener('resize', onWindowWidthChange);
  }, [])

  return (
    <Wrapper>
      {contextHolder}
      <div className={classes.search}>
        <div className={classes.tickets}>
          <div className={classes['tickets-container']}>
            <div className={classes['tickets-column']}>
              <DatePicker
                className={`${classes['tickets-datePick']} ${'tickets-dataPick'} ${'tickets-datePick'}`}
                popupClassName={classes['tickets-datePick-popup']}
                maxDate={moment('2030-01-01')}
                minDate={moment().add(-1, "days")}
                disabledDate={disabledDate}
                defaultOpenValue='true'
                defaultOpen='true'
                defaultValue={date ? dayjs(date) : selectedDate}
                hidden='false'
                open='true'
                placement='bottomLeft'
                placeholder='Выберите дату'
                onCalendarChange={(value) => dispatch(setSelectedDate(dayjs(value)))}
                getPopupContainer={trigger => trigger.parentElement}
              />
            </div>
            <div className={classes['tickets-column']}>
              <Input
                className={classes['tickets-dateInput']}
                value={`Дата   ${selectedDate.format('DD.MM.YYYY')}`}
                readOnly={true}
              />
              <div className={classes['tickets-route-title']}>Направление</div>
              <div className={classes['tickets-route-container']}>
                <Select
                  className={`${classes['tickets-route-container-select']} ${'tickets-route-container-select'}`}
                  showSearch
                  defaultValue={selectedFromStop}
                  value={selectedFromStop}
                  placeholder="Откуда"
                  label="true"
                  optionFilterProp='label'
                  onChange={(value) => dispatch(setSelectedFromStop(value))}
                >
                  {fromStopList.map(stop => 
                    <Select.Option value={stop.id} key={stop.id} label={stop.name}>{stop.name}</Select.Option>
                  )}
                </Select>
                <Select
                  className={`${classes['tickets-route-container-select']} ${'tickets-route-container-select'}`}
                  showSearch
                  defaultValue={selectedToStop}
                  value={selectedToStop}
                  placeholder="Куда"
                  label="true"
                  optionFilterProp='label'
                  onChange={(value) => dispatch(setSelectedToStop(value))}
                >
                  {toStopList.map(stop => 
                    <Select.Option value={stop.id} key={stop.id} label={stop.name}>{stop.name}</Select.Option>
                  )}
                </Select>
                <button style={{position: 'absolute', right: '0', display: 'flex', alignItems: 'center'}} onClick={() => switchStops()}>
                  <Icons
                    name='switch__primary'
                    size='24px'
                    className={classes['tickets-route-container-icon']} 
                  />
                </button> 
              </div>
            </div>
          </div>
          <Button
            loading={searchTripsButtonIsLoading}
            onClick={function(e) {
              fetchTrips()
            }}
            className={classes['tickets-button__confirm']}
          >
            Найти
          </Button>
        </div>
        {!isWindowWidthTablet &&        
          <div className={classes.preview}>
            <img className={classes['preview-image']} src={tripBanner} alt='Превью'/>
            <div className={classes['preview-image-iconContainer']}>
              <Icons
                name='long-arrow'
                size='32px'
                className={classes['preview-image-icon']} 
              />
            </div>
          </div>
        }
      </div>
      <div className={classes['tripsList']}>
        {isLoading.current
        ?
          <LoadingSpin type='component'/>
        :
        tripsList.length
          ?
            tripsList.map(data => 
              <Trip data={data} key={data.id}/>
            )
          :
            <div className={classes['tripsList-notFound-header']}>
              {!isWindowWidthMobile &&
                <div className={classes['tripsList-notFound-iconContainer']}>
                  <Icons
                    name='warning'
                    size='52px'
                    fill={blue1}
                    className={classes['tripsList-notFound-icon']}
                  />
                </div>
              }
              <div className={classes['tripsList-notFound-title']}>
              {!isWindowWidthMobile &&
                <svg style={{position: 'absolute', top: '0', left: '0'}} fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M 49.8719 116 C 81.8221 116 107.723 90.0325 107.723 58 C 107.723 25.9675 81.8221 0 49.8719 0 H 107.723 C 107.723 0 107.723 17.9086 107.723 40 V 76 C 107.723 98.0914 107.723 116 107.723 116 H 49.8719 Z M 14.8042 11.8669 C 9.8546 15.6545 0 12.3495 0 6.1169 V 6.1169 C 0 2.7386 2.7386 0 6.1169 0 H 49.8719 C 36.6876 0 24.5334 4.4218 14.8042 11.8669 Z M 0 109.883 C 0 103.65 9.8546 100.345 14.8042 104.133 C 24.5334 111.578 36.6876 116 49.8719 116 H 6.1169 C 2.7386 116 0 113.261 0 109.883 V 109.883 Z" fill={blue1}/>
                </svg>
              }
                По выбранным критериям рейсы не найдены
              </div>
            </div>
        }
      </div>
    </Wrapper>
  )
}

export default Tickets